/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import Caver from "caver-js";
import { ReactElement, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { recoilTradeState, TRADE_STATE } from "../../../atom/atom";
import { InitialKaikasInfoSchema } from "../../../constants/schema/initial_state_schema";

import dummy_image from "../../../img/dummy_image.png";
import { getExpactedClaimableAmount } from "../../../utils/staking_func";

const itemNFTWrapper = (
  isSelected: boolean,
  isLast: boolean,
  isDummy: boolean
) => css`
  width: 215px;
  height: 298px;
  font-family: "Baloo 2";
  border: 1px solid #afafaf;
  border-radius: 20px;

  cursor: pointer;
  border: ${isSelected ? 5 : 3}px solid ${isSelected ? "#FCF38F" : "#AFAFAF"};
  margin-right: ${isLast ? 20 : 34}px;
  display: flex;
  flex-direction: ${isDummy ? "row" : "column"};
  align-items: center;
  padding: 20px;
`;

interface ItemNFTProps {
  info: InitialKaikasInfoSchema;
  nftKey: string;
  isSelected: boolean;
  dataOfNFT: any;
  allofNFT?: string[];
  handleChangeSelectedItems: (isSelected: boolean, item: string) => void;
  isLast?: boolean;
  showClaimableInfo: boolean;
}

export default function ItemNFT({
  info,
  nftKey,
  isSelected,
  dataOfNFT,
  allofNFT,
  handleChangeSelectedItems,
  isLast = false,
  showClaimableInfo,
}: ItemNFTProps): ReactElement | null {
  if (nftKey === "dummy") {
    return null;
  }

  const [value, setValue] = useState<string>("0");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [photoUrl, setPhotoUrl] = useState<string>("");
  const [title, setTitle] = useState<string>("");

  const tradeState = useRecoilValue(recoilTradeState);
  const [showInfo, setShowInfo] = useState<boolean>(showClaimableInfo);

  const [setTimeoutId, setSetTimeoutId] = useState<NodeJS.Timer>();
  useEffect(() => {
    if (showClaimableInfo) {
      if (tradeState === TRADE_STATE.REFRESH) {
        setShowInfo(false);
      }
    }
  }, [tradeState]);

  useEffect(() => {
    if (allofNFT) {
      if (allofNFT.includes(nftKey)) setShowInfo(true);
    } else {
      setShowInfo(false);
      clearInterval(setTimeoutId);
    }
  }, [allofNFT, nftKey, setTimeoutId]);

  useEffect(() => {
    if (showInfo) {
      const nftSingleArray = [nftKey];
      getExpactedClaimableAmount(
        nftSingleArray,
        info.StakingContract,
        allofNFT
      ).then((data: string) => {
        if (data) {
          setValue(data);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (showInfo) {
      const nftSingleArray = [nftKey];

      const interval = setInterval(() => {
        if (allofNFT && !allofNFT.includes(nftKey)) {
          setSetTimeoutId(interval);
        }
        setSetTimeoutId(interval);
        getExpactedClaimableAmount(
          nftSingleArray,
          info.StakingContract,
          allofNFT,
          interval
        ).then((data: string) => {
          if (data) {
            setValue(data);
          }
        });
      }, 3000);

      return () => {
        clearInterval(interval);
      };
    } else {
      setValue("0");
    }
  }, [showInfo, allofNFT]);

  useEffect(() => {
    if (tradeState === TRADE_STATE.REFRESH) {
      clearInterval(setTimeoutId);
    }
  }, [setTimeoutId, tradeState]);

  useEffect(() => {
    if (nftKey !== "dummy") {
      if (dataOfNFT && dataOfNFT.image && dataOfNFT.name) {
        setIsLoading(false);
        setPhotoUrl(dataOfNFT.image);
        setTitle(dataOfNFT.name);
      }
    }
  }, [dataOfNFT]);

  const claimableValueForUser = Number(Caver.utils.fromPeb(value)).toFixed(6);

  return (
    <div
      css={itemNFTWrapper(isSelected, isLast, nftKey === "dummy")}
      onClick={() => {
        if (nftKey !== "dummy") {
          handleChangeSelectedItems(isSelected, nftKey);
        }
      }}
    >
      <div
        css={css`
          width: 175px;
          height: 175px;
          overflow: hidden;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
        `}
      >
        {isLoading && <img src={dummy_image} width={67} height={61} />}

        {!isLoading && photoUrl && (
          <img src={photoUrl} width={175} height={175} />
        )}
      </div>
      {nftKey !== "dummy" && title && (
        <div
          css={css`
            width: 100%;
            text-align: left;
          `}
        >
          <div
            css={css`
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              width: 100%;
            `}
          >
            {title}
          </div>

          {showInfo && (
            <div
              css={css`
                font-weight: 400;
                font-size: 16px;
                line-height: 120%;
                color: #515151;
              `}
            >
              <div>Claimable</div>
              <div>{`${claimableValueForUser} GGNZ`}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
