/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ReactElement } from "react";

import { SWAP_SLIPPAGE_RATE } from "../../constants/constant/swap_constants";
import { TOKEN_TYPE } from "../../constants/enums/enum";
import { SwapItemSchema } from "../../constants/schema/initial_state_schema";

const swapModalWrapper = css`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  .complete {
    .go-opensea-area {
      position: relative;
      height: 84px;
      margin: 50px auto 0;

      .button-background {
        position: absolute;
        width: calc(100% - 60px);
        background-color: #000;
        border-radius: 25px;
        z-index: -1;
        bottom: -3px;
        height: 80px;
        left: 50%;
        transform: translateX(-50%);
      }
      .go-opensea-button {
        width: calc(100% - 60px);
        border-radius: 25px;
        height: 80px;
        font-family: "Baloo 2";
        font-weight: 800;
        font-size: 22px;
        padding-bottom: 3px;
        cursor: pointer;
        color: #fff;
        background-color: #009b5c;
        box-shadow: 0px -6px 2px 1px #0a5436 inset;
        border: solid 3px #000;
        display: block;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .go-back {
      border: none;
      font-family: "Baloo 2";
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      color: #000;
      text-align: center;
      width: calc(100% - 60px);
      background: none;
      height: 80px;
      display: block;
      margin: 0 auto;
      margin-top: 8px;
      cursor: pointer;
    }
  }
`;

const layoutModal = css`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 200;
  position: absolute;
  width: 600px;
  height: 735px;
  border-radius: 35px;
  padding: 30px;
  color: black;
  font-family: "Baloo 2";
  font-style: normal;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const title = css`
  font-size: 30px;
`;

const colorGreen = css`
  color: #009b5c;
`;

const swapInfoBox = css`
  width: 544px;
  height: 143px;
  background: #eaeaea;
  border-radius: 16px;
  padding: 25px;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const rateBox = css`
  margin: 30px 0px 0px;
  width: 544px;
  height: 143px;
  border-radius: 16px;
  padding: 25px 0px;
  font-size: 18px;
`;

const itemBox = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const font700 = css`
  font-weight: 700;
`;

const font200 = css`
  font-weight: 200;
`;

const buttonSwapWrapper = css`
  position: relative;
  height: 84px;
`;

const buttonBackground = css`
  position: absolute;
  width: 100%;
  background-color: #000;
  border-radius: 25px;
  z-index: -1;
  bottom: -3px;
  height: 80px;
  left: 50%;
  transform: translateX(-50%);
`;

const buttonSwap = css`
  width: 100%;
  border-radius: 25px;
  height: 80px;
  font-family: "Baloo 2";
  font-weight: 800;
  font-size: 30px;
  padding-bottom: 3px;
  cursor: pointer;
  color: #fff;
  background-color: #009b5c;
  box-shadow: 0px -6px 2px 1px #0a5436 inset;
  border: solid 3px #000;
  display: block;
  margin: 0 auto;
  cursor: pointer;
`;

interface SwapModapProps {
  exchangeRate: number;
  calculatedAmount: number;
  calculatedFee: number;
  swapItems: [SwapItemSchema, SwapItemSchema];
  handleClickSwap: (toGGNZ: boolean, amount: number) => void;
  handleChangeSwapModal: (status: boolean) => void;
}

export default function SwapModal({
  exchangeRate,
  calculatedAmount,
  calculatedFee,
  swapItems,
  handleClickSwap,
  handleChangeSwapModal,
}: SwapModapProps): ReactElement {
  const [fromData, toData] = swapItems;

  const isToGGNZ = fromData.type === TOKEN_TYPE.KLAY;
  const minimumTransferAmount = calculatedAmount * SWAP_SLIPPAGE_RATE;

  return (
    <div
      css={swapModalWrapper}
      onClick={() => {
        handleChangeSwapModal(false);
      }}
    >
      <div
        css={layoutModal}
        onClick={(e) => {
          e.stopPropagation();
          handleChangeSwapModal(true);
        }}
      >
        <div>
          <div css={title}>Confirm Transaction</div>
          <div css={[title, colorGreen]}>Swap</div>
        </div>

        <div>
          <div css={swapInfoBox}>
            <div css={itemBox}>
              <div>FROM</div>
              <div>{`${fromData.amount} ${fromData.type}`}</div>
            </div>
            <div css={itemBox}>
              <div>TO</div>
              <div>{`${calculatedAmount} ${toData.type}`}</div>
            </div>
          </div>

          <div css={rateBox}>
            <div css={itemBox}>
              <div css={font700}>Exchange Rate</div>
              <div css={font200}>{exchangeRate}</div>
            </div>
            <div css={itemBox}>
              <div css={font700}>Minimum transfer amounts</div>
              <div css={font200}>
                {minimumTransferAmount} {toData.type}
              </div>
            </div>
            <div css={itemBox}>
              <div css={font700}>Fee</div>
              <div css={font200}>
                {calculatedFee} {toData.type}
              </div>
            </div>
          </div>
        </div>
        <div css={buttonSwapWrapper}>
          <div css={buttonBackground} />
          <button
            css={buttonSwap}
            onClick={(e) => {
              e.stopPropagation();
              handleClickSwap(isToGGNZ, fromData.amount);
            }}
          >
            SWAP
          </button>
        </div>
      </div>
    </div>
  );
}
