/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useRecoilValue } from "recoil";
import { recoilTradeState, TRADE_STATE } from "../../../atom/atom";
import { InitialKaikasInfoSchema } from "../../../constants/schema/initial_state_schema";
import ItemArrowLeft from "../../../img/svg/ItemArrowLeft";
import ItemArrowRight from "../../../img/svg/ItemArrowRight";
import ItemChecked from "../../../img/svg/ItemChecked";
import { parseTokensURI } from "../../../utils/staking_func";

import ItemNFT from "./ItemNFT";
import { STAKING_TAB } from "./StakingBox";

interface StakingItemsBoxProps {
  info: InitialKaikasInfoSchema;
  stakingTab: STAKING_TAB;
  myWalletNFT: string[][];
  stakedNFT: string[][];
  page: number;
  dataOfNFT: any;

  setPage: Dispatch<SetStateAction<number>>;
  selectedNFTItem: string[];
  handleChangeSelectedNFTItem: (items: string[]) => void;
  resetSelectedNFTItem: () => void;
  selectAllNFTItem: () => void;
}

const layout = css`
  width: 100%;
`;

const itemWrapper = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 25px 20px;
`;

const allCheckBox = (isAllChecked: boolean) => css`
  cursor: pointer;
  width: 19px;
  height: 19px;
  left: 125px;
  top: 270px;
  border: 3px solid #515151;
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${isAllChecked ? "#515151" : "transparent"};
`;

const textAll = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  color: #515151;
`;

const items = css`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
`;

export default function StakingItemsBox({
  info,
  stakingTab,
  myWalletNFT,
  stakedNFT,
  page,
  dataOfNFT,

  setPage,
  selectedNFTItem,
  handleChangeSelectedNFTItem,
  resetSelectedNFTItem,
  selectAllNFTItem,
}: StakingItemsBoxProps): ReactElement {
  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
  const recoilTradeStatus = useRecoilValue(recoilTradeState);

  useEffect(() => {
    if (recoilTradeStatus === TRADE_STATE.REFRESH) {
      resetSelectedNFTItem();
      setIsAllChecked(false);
    }
  }, [recoilTradeStatus]);

  useEffect(() => {
    resetSelectedNFTItem();
    setIsAllChecked(false);
  }, [stakingTab]);

  const handleChangeSelectedItems = (isSelected: boolean, item: string) => {
    if (isSelected) {
      const newItem = selectedNFTItem.filter((el) => el !== item);

      handleChangeSelectedNFTItem(newItem);
    } else {
      const newItem = [...selectedNFTItem, item];
      handleChangeSelectedNFTItem(newItem);
    }
  };

  useEffect(() => {
    if (dataOfNFT.total.length === 0) {
      (async () => {
        await parseTokensURI(1, dataOfNFT.v1);
        await parseTokensURI(2, dataOfNFT.v2);
      })();
    }
  }, [dataOfNFT]);

  const showCheckAllBox =
    (stakingTab === STAKING_TAB.MY_WALLET &&
      myWalletNFT.flat().filter((el) => el !== "dummy").length > 0) ||
    (stakingTab === STAKING_TAB.STAKED_NFT &&
      stakedNFT.flat().filter((el) => el !== "dummy").length > 0);

  return (
    <div css={layout}>
      {showCheckAllBox && (
        <div css={itemWrapper}>
          <div
            css={allCheckBox(isAllChecked)}
            onClick={() => {
              if (isAllChecked) {
                resetSelectedNFTItem();
                setIsAllChecked(false);
              } else {
                selectAllNFTItem();
                setIsAllChecked(true);
              }
            }}
          >
            {isAllChecked && <ItemChecked />}
          </div>
          <span css={textAll}>ALL</span>
        </div>
      )}
      <div css={items}>
        {showCheckAllBox && (
          <div
            css={css`
              cursor: ${page > 1 ? "cursor" : "default"};
              padding-right: 18px; ;
            `}
            onClick={() => {
              if (page > 1) {
                setPage((prev) => prev - 1);
              }
            }}
          >
            <ItemArrowLeft color={page > 1 ? "black" : "#EAEAEA"} />
          </div>
        )}
        <div
          css={css`
            width: 100%;
            display: flex;
          `}
        >
          {stakingTab === STAKING_TAB.MY_WALLET &&
            myWalletNFT[page - 1] &&
            myWalletNFT[page - 1].map((el, index, all) => {
              const [_NFTKind, _NFTNumber] = el.split(" ");

              const data = dataOfNFT.total.find(
                // NOTE - 테스트넷 작업시 find조건 교체
                // (ele:any)=> {
                // const [_version,_edition] = ele.name.split("#");
                // return  _NFTNumber === _edition && _version.startWith(_NFTKind)
                // }
                (ele: any) => ele.edition === Number(_NFTNumber)
              );

              return (
                <ItemNFT
                  key={el === "dummy" ? el + Math.random() : el}
                  info={info}
                  nftKey={el}
                  isSelected={selectedNFTItem.includes(el)}
                  dataOfNFT={data}
                  handleChangeSelectedItems={handleChangeSelectedItems}
                  isLast={index === all.length - 1}
                  showClaimableInfo={false}
                />
              );
            })}
          {stakingTab === STAKING_TAB.STAKED_NFT &&
            stakedNFT[page - 1] &&
            stakedNFT[page - 1].map((el, index, all) => {
              const [, number] = el.split(" ");

              const data = dataOfNFT.total.find(
                (ele: any) => ele.edition === Number(number)
              );

              return (
                <ItemNFT
                  info={info}
                  key={el === "dummy" ? el + index : el}
                  nftKey={el}
                  allofNFT={all.flat().filter((el) => el !== "dummy")}
                  isSelected={selectedNFTItem.includes(el)}
                  dataOfNFT={data}
                  handleChangeSelectedItems={handleChangeSelectedItems}
                  isLast={index === all.length - 1}
                  showClaimableInfo
                />
              );
            })}
        </div>
        {showCheckAllBox && (
          <div
            css={css`
              cursor: ${(stakingTab === STAKING_TAB.MY_WALLET &&
                myWalletNFT.length > page) ||
              (stakingTab === STAKING_TAB.STAKED_NFT && stakedNFT.length > page)
                ? "pointer"
                : "default"};
            `}
            onClick={() => {
              if (
                stakingTab === STAKING_TAB.MY_WALLET &&
                myWalletNFT.length > page
              ) {
                setPage((prev) => prev + 1);
              }
              if (
                stakingTab === STAKING_TAB.STAKED_NFT &&
                stakedNFT.length > page
              ) {
                setPage((prev) => prev + 1);
              }
            }}
          >
            <ItemArrowRight
              color={
                (stakingTab === STAKING_TAB.MY_WALLET &&
                  myWalletNFT.length > page) ||
                (stakingTab === STAKING_TAB.STAKED_NFT &&
                  stakedNFT.length > page)
                  ? "black"
                  : "#EAEAEA"
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}
