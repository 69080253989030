import { TOKEN_TYPE } from "../enums/enum";
import {
  ClaimableAmountInfoSchema,
  InitialKaikasInfoSchema,
  MyOwnNFTInfoSchema,
  SwapItemSchema,
  SwapRateInfoSchema,
} from "../schema/initial_state_schema";

export const initialKaikasInfoState: InitialKaikasInfoSchema = {
  connected: false,
  isOwner: false,
  account: null,
  nftIds: {
    v1: [],
    v2: [],
  },
};

export const initialSwapItemsInfo: [SwapItemSchema, SwapItemSchema] = [
  {
    type: TOKEN_TYPE.KLAY,
    amount: 0,
    balance: 0,
  },
  {
    type: TOKEN_TYPE.GGNZ,
    amount: 0,
    balance: 0,
  },
];

export const initalExchangeRateInfo: SwapRateInfoSchema = {
  exchangeRate: 1,
  slippageRate: 1,
};

export const initialMyOwnNFTInfo: MyOwnNFTInfoSchema = {
  stakedNFT: [],
  unstakedNFT: [],
};

export const initialClaimableAmountInfo: ClaimableAmountInfoSchema = {
  initialValue: 0,
  increaseRate: 0,
};
