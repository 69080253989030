interface ItemArrowLeftProps {
  color?: string;
}
export default function ItemArrowRight({
  color = "#EAEAEA",
}: ItemArrowLeftProps): JSX.Element {
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.488156 16.5021C-0.162717 15.8382 -0.162717 14.7618 0.488157 14.0979L5.97631 8.5L0.488158 2.90208C-0.162715 2.23819 -0.162715 1.16181 0.488158 0.497918C1.13903 -0.165973 2.19431 -0.165972 2.84518 0.497918L9.51185 7.29792C10.1627 7.96181 10.1627 9.03819 9.51185 9.70208L2.84518 16.5021C2.1943 17.166 1.13903 17.166 0.488156 16.5021Z"
        fill={color}
      />
    </svg>
  );
}
