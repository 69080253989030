import Caver from "caver-js";
import { WALLET_TYPE } from "../../atom/wallet_address";
import { getNFTIds } from "../staking_func";

declare global {
  interface Window {
    ethereum: any;
    klaytn: any;
  }
}

export const init = async (
  walletType: WALLET_TYPE,
  _contractJSON: any,
  _GGNZContractJSON: any,
  _NFT_V1_JSON: any,
  _NFT_V2_JSON: any,
  _swapJSON: any,
  _StakingJSON: any,
  _Vault_V1_JSON: any,
  _Vault_V2_JSON: any,
  account: string
) => {
  if (walletType === WALLET_TYPE.KAIKAS) {
    if (window.klaytn.isKaikas) {
      try {
        const networkId = await window.klaytn.networkVersion;

        if (networkId == _contractJSON.chain_id) {
          const caver = new Caver(window.klaytn);
          const contract = new caver.klay.Contract(
            _contractJSON.abi,
            _contractJSON.address
          );

          const GGNZContract = new caver.klay.Contract(
            _GGNZContractJSON.abi,
            _GGNZContractJSON.contractAddress
          );

          const NFT_V1Contract = new caver.klay.Contract(
            _NFT_V1_JSON.abi,
            _NFT_V1_JSON.contractAddress
          );
          const NFT_V2Contract = new caver.klay.Contract(
            _NFT_V2_JSON.abi,
            _NFT_V2_JSON.contractAddress
          );

          const SWAPContract = new caver.klay.Contract(
            _swapJSON.abi,
            _swapJSON.contractAddress
          );

          const StakingContract = new caver.klay.Contract(
            _StakingJSON.abi,
            _StakingJSON.contractAddress
          );

          const Vault_V1Contract = new caver.klay.Contract(
            _Vault_V1_JSON.abi,
            _Vault_V1_JSON.contractAddress
          );
          const Vault_V2Contract = new caver.klay.Contract(
            _Vault_V2_JSON.abi,
            _Vault_V2_JSON.contractAddress
          );

          const isOwner = await handleOwnerChanged("klay_getAccount", account);

          const idsOfV1 = await getNFTIds({
            version: 1,
            contract: NFT_V1Contract,
            account: account,
          });

          const idsOfV2 = await getNFTIds({
            version: 2,
            contract: NFT_V2Contract,
            account: account,
          });

          const currentState = {
            isOwner,
            connected: true,
            account: account,
            caver: caver,
            contract,
            GGNZContract,
            NFT_V1Contract,
            NFT_V2Contract,
            SWAPContract,
            StakingContract,
            Vault_V1Contract,
            Vault_V2Contract,
            nftIds: {
              v1: idsOfV1,
              v2: idsOfV2,
            },
            contractJSON: _contractJSON,
          };

          return currentState;
        }
      } catch (err) {
        return {};
      }
    } else {
      const currentState = {
        status: "Please install Kaikas.",
      };
      return currentState;
    }
  } else {
    const urls = [
      "https://public-en-cypress.klaytn.net",
      "https://archive-en.cypress.klaytn.net",
      "https://archive-en.cypress.klaytn.net",
      "https://klaytn-mainnet-rpc.allthatnode.com:8551",
      "https://klaytn.blockpi.network/v1/rpc/public",
    ];

    const url = urls[Math.floor(Math.random() * urls.length)];

    try {
      const caver = new Caver(new Caver.providers.HttpProvider(url));
      const contract = new caver.klay.Contract(
        _contractJSON.abi,
        _contractJSON.address
      );

      const GGNZContract = new caver.klay.Contract(
        _GGNZContractJSON.abi,
        _GGNZContractJSON.contractAddress
      );

      const NFT_V1Contract = new caver.klay.Contract(
        _NFT_V1_JSON.abi,
        _NFT_V1_JSON.contractAddress
      );
      const NFT_V2Contract = new caver.klay.Contract(
        _NFT_V2_JSON.abi,
        _NFT_V2_JSON.contractAddress
      );

      const SWAPContract = new caver.klay.Contract(
        _swapJSON.abi,
        _swapJSON.contractAddress
      );

      const StakingContract = new caver.klay.Contract(
        _StakingJSON.abi,
        _StakingJSON.contractAddress
      );

      const Vault_V1Contract = new caver.klay.Contract(
        _Vault_V1_JSON.abi,
        _Vault_V1_JSON.contractAddress
      );
      const Vault_V2Contract = new caver.klay.Contract(
        _Vault_V2_JSON.abi,
        _Vault_V2_JSON.contractAddress
      );

      const isOwner = await handleOwnerChanged("klay_getAccount", account);

      const idsOfV1 = await getNFTIds({
        version: 1,
        contract: NFT_V1Contract,
        account: account,
      });

      const idsOfV2 = await getNFTIds({
        version: 2,
        contract: NFT_V2Contract,
        account: account,
      });

      const currentState = {
        isOwner,
        connected: true,
        account: account,
        caver: caver,
        contract,
        GGNZContract,
        NFT_V1Contract,
        NFT_V2Contract,
        SWAPContract,
        StakingContract,
        Vault_V1Contract,
        Vault_V2Contract,
        nftIds: {
          v1: idsOfV1,
          v2: idsOfV2,
        },
        contractJSON: _contractJSON,
      };

      return currentState;
    } catch (err) {
      return {};
    }
  }
};

export const handleOwnerChanged = async (
  contract: any,
  account: any
): Promise<boolean> => {
  try {
    const result = await contract.methods.owner().call({ from: account });

    if (account === result.toLowerCase()) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};
