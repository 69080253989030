/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import Caver from "caver-js";
import { useEffect, useState } from "react";

import { UNSTAKED_MODAL_TYPE } from "../../../constants/enums/enum";
import { InitialKaikasInfoSchema } from "../../../constants/schema/initial_state_schema";
import { getExpactedClaimableAmount } from "../../../utils/staking_func";
let setIntervalForEverySecond: NodeJS.Timer;

interface EarnedRateProps {
  info: InitialKaikasInfoSchema;
  stakedNFT: string[][];
  claimOrUnstakeButtonAvailable: boolean;

  handleClickOpenStakingModal: () => void;
  handleChangeModalType: (type: UNSTAKED_MODAL_TYPE) => void;
}

const buttonBoxWrapper = css`
  width: 100%;
  max-width: 450px;
  display: flex;
  justify-content: space-between;
`;

const swapButtonWrapper = css`
  position: relative;

  height: 65px;

  .mint-button-background {
    position: absolute;

    width: 216px;
    background-color: #515151;
    border-radius: 25px;
    z-index: -1;
    bottom: -3px;
    height: 60px;

    &.active {
      background-color: #000;
    }
  }
  .mint-button {
    width: 216px;
    background-color: #eaeaea;
    border-radius: 25px;
    box-shadow: inset 0px -6px 2px 1px #afafaf;
    height: 65px;
    border: solid 3px #515151;
    font-family: "Baloo 2";
    font-weight: 800;
    font-size: 24px;
    color: #afafaf;
    padding-bottom: 3px;
    cursor: pointer;

    &.active {
      background-color: #009b5c;
      box-shadow: 0px -6px 2px 1px #0a5436 inset;
      border: solid 3px #000;
      color: #fff;
      :hover {
        margin-top: 5px;
        box-shadow: inset 0px -1px 2px 1px #0a5436;
      }
    }
  }
`;

export default function StakedNFTTabInfoBox({
  info,
  stakedNFT,
  claimOrUnstakeButtonAvailable,
  handleClickOpenStakingModal,
  handleChangeModalType,
}: EarnedRateProps) {
  const [value, setValue] = useState<string>("0");
  const [increaseRate, setIncreaseRate] = useState<string>("0");
  const getClaimableValue = (rate: string) => {
    setValue((prev) => {
      const BnRate = Caver.utils.toBN(prev).add(Caver.utils.toBN(rate));
      const BnRateToString = BnRate.toString();

      return BnRateToString;
    });
  };
  const [setTimeoutReturn, setSetTimeoutReturn] = useState<any>();

  useEffect(() => {
    const plattenArray = stakedNFT.flat().filter((el) => el !== "dummy");

    if (plattenArray.length > 0) {
      const setTiemoutId = setTimeout(() => {
        getExpactedClaimableAmount(plattenArray, info.StakingContract).then(
          (data) => {
            if (data) {
              return setSetTimeoutReturn(data);
            }
          }
        );
      }, 1000);

      new Promise((res) => {
        res(getExpactedClaimableAmount(plattenArray, info.StakingContract));
      }).then((data: any) => {
        setValue(data);
      });

      return () => {
        clearTimeout(setTiemoutId);
      };
    }
  }, []);

  useEffect(() => {
    if (setTimeoutReturn) {
      const BnRate = Caver.utils
        .toBN(setTimeoutReturn)
        .sub(Caver.utils.toBN(value));
      const BnRateToString = BnRate.toString();

      setIncreaseRate(BnRateToString);

      return () => {
        setIncreaseRate("0");
      };
    }
  }, [setTimeoutReturn]);

  const claimableValueForUser = Number(Caver.utils.fromPeb(value)).toFixed(6);
  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0px 20px;
      `}
    >
      <div
        css={css`
          width: 100%;
          max-width: 480px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: flex-start;
            font-family: "Baloo 2";
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 100%;

            color: #7bc8f3;
            padding-bottom: 20px;
          `}
        >
          YOU EARNED
        </div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 160%;
          `}
        >
          <div>Claimable</div>
          <div
            css={css`
              text-align: right;
            `}
          >
            <div>
              {claimableValueForUser}
              {(() => {
                clearInterval(setIntervalForEverySecond);
                setIntervalForEverySecond = setInterval(() => {
                  getClaimableValue(increaseRate);
                  return () => clearInterval(setIntervalForEverySecond);
                }, 1000);
              })()}{" "}
              GGNZ
            </div>
            <div>~$ 0</div>
          </div>
        </div>
      </div>
      <div css={buttonBoxWrapper}>
        <div css={swapButtonWrapper}>
          <div
            className={`mint-button-background ${
              claimOrUnstakeButtonAvailable ? "active" : ""
            }`}
          />
          <button
            className={`mint-button ${
              claimOrUnstakeButtonAvailable ? "active" : ""
            }`}
            onClick={() => {
              if (claimOrUnstakeButtonAvailable) {
                handleChangeModalType(UNSTAKED_MODAL_TYPE.UNSTAKE);
                handleClickOpenStakingModal();
              }
            }}
          >
            UNSTAKE
          </button>
        </div>
        <div css={swapButtonWrapper}>
          <div
            className={`mint-button-background ${
              claimOrUnstakeButtonAvailable ? "active" : ""
            }`}
          />
          <button
            className={`mint-button ${
              claimOrUnstakeButtonAvailable ? "active" : ""
            }`}
            onClick={() => {
              if (claimOrUnstakeButtonAvailable) {
                handleChangeModalType(UNSTAKED_MODAL_TYPE.CLAIM);
                handleClickOpenStakingModal();
              }
            }}
          >
            CLAIM
          </button>
        </div>
      </div>
    </div>
  );
}
