import { atom } from "recoil";
import { initialKaikasInfoState } from "../constants/initialState/initial_state";
import { InitialKaikasInfoSchema } from "../constants/schema/initial_state_schema";

export enum WALLET_TYPE {
  KAIKAS = "kaikas",
  KLIP = "klip",
}

export const walletTypeState = atom<WALLET_TYPE | null>({
  key: "walletTypeState",
  default: null,
});

export const walletAddressState = atom<string | null>({
  key: "walletAddressState",
  default: null,
});

export const connectInfoState = atom<InitialKaikasInfoSchema>({
  key: "initialInfoState",
  default: initialKaikasInfoState,
});
