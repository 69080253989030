import { SwapRateInfoSchema } from "../constants/schema/initial_state_schema";

export const getBalanceOfKLAY = async (
  caver: any,
  address: string | null
): Promise<number> => {
  if (address) {
    try {
      const result = await caver.klay.getBalance(address);
      return caver.utils.convertFromPeb(result);
    } catch (err) {
      return 0;
    }
  } else {
    return 0;
  }
};

export const getBalanceOfGGNZ = async (
  contract: any,
  caver: any,
  address: string | null
): Promise<number> => {
  if (address) {
    try {
      const result = await contract.call("balanceOf", address);

      return caver.utils.convertFromPeb(result);
    } catch (err) {
      return 0;
    }
  } else {
    return 0;
  }
};

export const getSWAPReserves = async (
  contract: any,
  caver: any
): Promise<SwapRateInfoSchema> => {
  try {
    const coinReserve = await contract.call("coinReserve");
    const tokenReserve = await contract.call("tokenReserve");

    return {
      coinReserve: Number(caver.utils.convertFromPeb(coinReserve, "mKLAY")),
      tokenReserve: Number(caver.utils.convertFromPeb(tokenReserve, "mKLAY")),
    };
  } catch (err) {
    return { coinReserve: 1, tokenReserve: 1 };
  }
};

export const getCalculateFee = async (
  contract: any,
  caver: any,
  address: string | null,
  toGGNZ: boolean,
  amount: number
): Promise<number> => {
  if (address) {
    try {
      const amountToPeb = getPebFromKlay(caver, String(amount));
      const result = await contract.methods
        .calculateFee(toGGNZ, amountToPeb)
        .call({ from: address });

      const resultFromPeb = Number(caver.utils.fromPeb(result, "KLAY"));
      return resultFromPeb;
    } catch (err) {
      return 0;
    }
  } else {
    return 0;
  }
};

export const getCalculatedTransferAmount = async (
  contract: any,
  caver: any,
  address: string | null,
  toGGNZ: boolean,
  amount: number
): Promise<number> => {
  if (address) {
    try {
      const amountToPeb = getPebFromKlay(caver, String(amount));

      const result = await contract.methods
        .calculateOutput(toGGNZ, amountToPeb)
        .call({ from: address });

      const resultFromPeb = Number(caver.utils.fromPeb(result, "KLAY"));

      return resultFromPeb;
    } catch (err) {
      return 0;
    }
  } else {
    return 0;
  }
};

export function getPebFromKlay(caver: any, _amount: string) {
  let [head, tail] = _amount.split(".");
  const endFix = "000000000000000000";
  let result;
  if (head && !tail) {
    result = caver.utils.toBN(head + endFix);
  } else if (head && tail) {
    result = caver.utils.toBN(head + tail);
  } else if (!head && tail) {
    while (tail[0] === "0") {
      tail = tail.slice(1);
    }
    result = caver.utils.toBN(tail);
  } else if (!head && !tail) {
    result = "0";
  }
  head = "";
  return result;
}

export const swap = async (
  contract: any,
  caver: any,
  address: string | null,
  toGGNZ: boolean,
  amount: number,
  deadLine: number
): Promise<boolean> => {
  if (address) {
    try {
      const amountToPeb = getPebFromKlay(caver, String(amount));
      let calculatedOutput = await contract.methods
        .calculateOutput(toGGNZ, amountToPeb)
        .call();
      calculatedOutput = caver.utils.toBN(calculatedOutput);
      calculatedOutput = calculatedOutput
        .mul(caver.utils.toBN("995"))
        .div(caver.utils.toBN("1000"));

      await contract.methods
        .swapExecute(
          toGGNZ,
          amountToPeb,
          address,
          calculatedOutput.toString(),
          deadLine + 1800
        )
        .send({
          from: address,
          gas: 1500000,
          value: toGGNZ ? amountToPeb : "0x0",
        });

      return true;
    } catch (err) {
      return false;
    }
  } else {
    return false;
  }
};
