import { prepare, request, getResult, getCardList } from "klip-sdk";

export async function klipAuth() {
  const bappName = "GGNZ";

  const res = await prepare.auth({ bappName });

  return {
    requestKey: res.request_key || "",
    expiration_time: res.expiration_time || 0,
  };
}
export async function getKlipResult(key) {
  const res = await getResult(key);

  return res;
}

export const klipRequestClaim = async (v1, v2, contract) => {
  const v1Ids = v1.ids.map((x) => Number(x.split(" ")[1]));
  const v2Ids = v2.ids.map((x) => Number(x.split(" ")[1]));

  const bappName = "GGNZ";

  const to = contract?._address;
  const value = "0";
  const abi = `{
    "inputs": [
        {
            "internalType": "uint256[]",
            "name": "v1Ids",
            "type": "uint256[]"
        },
        {
            "internalType": "uint256[]",
            "name": "v2Ids",
            "type": "uint256[]"
        },
        {
            "internalType": "uint256[]",
            "name": "v3Ids",
            "type": "uint256[]"
        }
    ],
    "name": "claimRewardV1V2V3",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}`;
  const params = JSON.stringify([v1Ids, v2Ids, []]);

  const res = await prepare.executeContract({
    bappName,
    to,
    value,
    abi,
    params,
  });
  if (res.err) {
    return "err";
  } else if (res.request_key) {
    return "https://klipwallet.com/?target=/a2a?request_key=" + res.request_key;
  } else {
    return "err";
  }
};

export const klipRequestUnstake = async (v1, v2, contract) => {
  const v1Ids = v1.ids.map((x) => Number(x.split(" ")[1]));
  const v2Ids = v2.ids.map((x) => Number(x.split(" ")[1]));

  const bappName = "GGNZ";

  const to = contract?._address;
  const value = "0";
  const abi = `{
    "inputs": [
        {
            "internalType": "uint256[]",
            "name": "v1Ids",
            "type": "uint256[]"
        },
        {
            "internalType": "uint256[]",
            "name": "v2Ids",
            "type": "uint256[]"
        },
        {
            "internalType": "uint256[]",
            "name": "v3Ids",
            "type": "uint256[]"
        }
    ],
    "name": "unStakeV1V2V3",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}`;
  const params = JSON.stringify([v1Ids, v2Ids, []]);

  const res = await prepare.executeContract({
    bappName,
    to,
    value,
    abi,
    params,
  });
  if (res.err) {
    return "err";
  } else if (res.request_key) {
    return "https://klipwallet.com/?target=/a2a?request_key=" + res.request_key;
  } else {
    return "err";
  }
};

export const klipRequestStake = async (v1, v2, contract) => {
  const v1Ids = v1.ids.map((x) => Number(x.split(" ")[1]));
  const v2Ids = v2.ids.map((x) => Number(x.split(" ")[1]));

  const bappName = "GGNZ";

  const to = contract?._address;
  const value = "0";
  const abi = `{
    "inputs": [
        {
            "internalType": "uint256[]",
            "name": "v1Ids",
            "type": "uint256[]"
        },
        {
            "internalType": "uint256[]",
            "name": "v2Ids",
            "type": "uint256[]"
        },
        {
            "internalType": "uint256[]",
            "name": "v3Ids",
            "type": "uint256[]"
        }
    ],
    "name": "stakeV1V2V3",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}`;
  const params = JSON.stringify([v1Ids, v2Ids, []]);

  const res = await prepare.executeContract({
    bappName,
    to,
    value,
    abi,
    params,
  });
  if (res.err) {
    return "err";
  } else if (res.request_key) {
    return "https://klipwallet.com/?target=/a2a?request_key=" + res.request_key;
  } else {
    return "err";
  }
};
