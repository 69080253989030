/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ReactElement, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { walletTypeState, WALLET_TYPE } from "../../atom/wallet_address";
import { UNSTAKED_MODAL_TYPE } from "../../constants/enums/enum";
import { InitialKaikasInfoSchema } from "../../constants/schema/initial_state_schema";
import { getExpactedClaimableAmount } from "../../utils/staking_func";
import QRCode from "qrcode";
import { addBN, getNumberForDisplayUser } from "../../utils/utils";
import { getKlipResult } from "../../utils/klipUtils/auth";
import klip_img from "../../img/klip.png";
import CountDown from "../../utils/CountDown";

const swapModalWrapper = css`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  .complete {
    .go-opensea-area {
      position: relative;
      height: 84px;
      margin: 50px auto 0;

      .button-background {
        position: absolute;
        width: calc(100% - 60px);
        background-color: #000;
        border-radius: 25px;
        z-index: -1;
        bottom: -3px;
        height: 80px;
        left: 50%;
        transform: translateX(-50%);
      }
      .go-opensea-button {
        width: calc(100% - 60px);
        border-radius: 25px;
        height: 80px;
        font-family: "Baloo 2";
        font-weight: 800;
        font-size: 22px;
        padding-bottom: 3px;
        cursor: pointer;
        color: #fff;
        background-color: #009b5c;
        box-shadow: 0px -6px 2px 1px #0a5436 inset;
        border: solid 3px #000;
        display: block;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .go-back {
      border: none;
      font-family: "Baloo 2";
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      color: #000;
      text-align: center;
      width: calc(100% - 60px);
      background: none;
      height: 80px;
      display: block;
      margin: 0 auto;
      margin-top: 8px;
      cursor: pointer;
    }
  }
`;

const layoutModal = (isKlip: boolean) => css`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${isKlip ? "#1C6FF1" : "#ffffff"};
  z-index: 200;
  position: absolute;
  width: 600px;
  height: 453px;
  border-radius: 35px;
  padding: 30px;
  color: black;
  font-family: "Baloo 2";
  font-style: normal;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const title = css`
  font-size: 30px;
`;

const swapInfoBox = css`
  width: 544px;
  height: 143px;
  background: #eaeaea;
  border-radius: 16px;
  padding: 25px;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const itemBox = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const buttonSwapWrapper = css`
  position: relative;
  height: 84px;
`;

const buttonBackground = css`
  position: absolute;
  width: 100%;
  background-color: #000;
  border-radius: 25px;
  z-index: -1;
  bottom: -3px;
  height: 80px;
  left: 50%;
  transform: translateX(-50%);
`;

const buttonSwap = css`
  width: 100%;
  border-radius: 25px;
  height: 80px;
  font-family: "Baloo 2";
  font-weight: 800;
  font-size: 30px;
  padding-bottom: 3px;
  cursor: pointer;
  color: #fff;
  background-color: #009b5c;
  box-shadow: 0px -6px 2px 1px #0a5436 inset;
  border: solid 3px #000;
  display: block;
  margin: 0 auto;
  cursor: pointer;
`;

interface SwapModapProps {
  info: InitialKaikasInfoSchema;
  modalType: UNSTAKED_MODAL_TYPE;
  selectedNFTItem: string[];
  accuredRewards: string;
  handleClickClaim: (selectedItems: string[]) => void;
  getClaimQrAddress: (selectedItems: string[]) => Promise<string>;
  getUnstakingQrAddress: (selectedItems: string[]) => Promise<string>;
  updateInfoAfterKlipClaim: (selectedItems: string[], result: boolean) => void;
  updateInfoAFterKlipUnstaking: (
    selectedItems: string[],
    result: boolean
  ) => void;
  handleClickUnstaking: (selectedItems: string[]) => void;
  handleClickOpenStakingModal: () => void;
  resetSelectedNFTItem: () => void;
}

export default function StakingModal({
  info,
  modalType,
  selectedNFTItem,
  accuredRewards,
  handleClickClaim,
  getClaimQrAddress,
  getUnstakingQrAddress,
  updateInfoAfterKlipClaim,
  updateInfoAFterKlipUnstaking,
  handleClickUnstaking,
  handleClickOpenStakingModal,
  resetSelectedNFTItem,
}: SwapModapProps): ReactElement {
  const [qr, setQr] = useState<string>("");

  const [qrImage, setQrImage] = useState<string>("");
  const walletType = useRecoilValue(walletTypeState);
  const [expectedClaimableAmount, setExpectedClaimableAmount] =
    useState<string>("0");

  const [trySignIn, setTrySignIn] = useState<boolean>(false);
  const [timerStart, setTimerStart] = useState<boolean>(false);
  const [timerDone, setTimerDone] = useState<boolean>(false);

  useEffect(() => {
    const promise: Promise<string> = Promise.resolve(
      getExpactedClaimableAmount(selectedNFTItem, info.StakingContract)
    );
    promise.then((result) => {
      setExpectedClaimableAmount(result);
    });
  }, []);

  const expectedAccuredRewards = getNumberForDisplayUser(
    addBN(expectedClaimableAmount, accuredRewards)
  );

  useEffect(() => {
    if (qr && qr !== "err") {
      QRCode.toDataURL(qr).then((url) => {
        setTimerStart(true);
        setQrImage(url);
      });
    }
  }, [qr]);

  useEffect(() => {
    if (timerStart && !timerDone) {
      const key = qr.split("?request_key=")[1];

      if (key) {
        getKlipResult(key).then((data) => {
          if (data.status !== "completed") {
            setTimeout(() => {
              setTrySignIn((prev) => !prev);
            }, 1000);
          } else {
            if (modalType === UNSTAKED_MODAL_TYPE.CLAIM) {
              updateInfoAfterKlipClaim(selectedNFTItem, true);
            } else {
              updateInfoAFterKlipUnstaking(selectedNFTItem, true);
            }

            resetSelectedNFTItem();
            handleClickOpenStakingModal();
          }
        });
      }
    }
  }, [timerStart, timerDone, trySignIn]);

  useEffect(() => {
    if (timerDone) {
      handleClickOpenStakingModal();
    }
  }, [timerDone]);

  return (
    <div css={swapModalWrapper} onClick={handleClickOpenStakingModal}>
      <div
        css={layoutModal(qrImage && qrImage.length > 0 ? true : false)}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {qrImage ? (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                font-size: 22px;
                line-height: 160%;
                /* or 35px */

                text-align: center;

                color: #ffffff;
              `}
            >
              <span
                css={css`
                  padding-top: 8px;
                  margin-right: 10px;
                `}
              >
                <img width={25} src={klip_img} />
              </span>
              <span
                css={css`
                  font-family: "CookieRun 400";
                  font-weight: 100;
                `}
              >
                Klip QR 서명
              </span>
            </div>
            <div
              css={css`
                margin: 50px 0px;
              `}
            >
              <img src={qrImage} width={150} />
            </div>

            <div
              css={css`
                font-family: "CookieRun 400";
                display: flex;
                justify-content: center;

                font-size: 16px;
                line-height: 160%;
                /* identical to box height, or 26px */
                font-weight: 100;
                text-align: center;

                color: #eaeaea;
              `}
            >
              <span>남은 시간</span>
              {timerStart && <CountDown minNum={5} setDone={setTimerDone} />}
            </div>
            <div
              css={css`
                font-size: 16px;
                line-height: 160%;
                /* identical to box height, or 26px */
                font-family: "CookieRun 400";
                font-weight: 100;
                text-align: center;

                color: #eaeaea;
              `}
            >
              QR코드 리더기 또는 카카오톡 앱으로 QR코드를 스캔해 주세요
            </div>
          </div>
        ) : (
          <>
            <div>
              <div css={title}>Confirm Transaction</div>
            </div>

            <div>
              <div css={swapInfoBox}>
                <div css={itemBox}>
                  <div>Claimable</div>
                  <div>{getNumberForDisplayUser(expectedClaimableAmount)}</div>
                </div>
                <div css={itemBox}>
                  <div>Expected Accrued Reward</div>
                  <div>{expectedAccuredRewards}</div>
                </div>
              </div>
            </div>
            <div css={buttonSwapWrapper}>
              <div css={buttonBackground} />
              <button
                css={buttonSwap}
                onClick={() => {
                  if (modalType === UNSTAKED_MODAL_TYPE.CLAIM) {
                    if (walletType === WALLET_TYPE.KLIP) {
                      const address = getClaimQrAddress(selectedNFTItem);
                      address.then((data) => setQr(data));
                    } else {
                      handleClickClaim(selectedNFTItem);
                      resetSelectedNFTItem();
                      handleClickOpenStakingModal();
                    }
                  } else {
                    if (walletType === WALLET_TYPE.KLIP) {
                      const address = getUnstakingQrAddress(selectedNFTItem);
                      address.then((data) => setQr(data));
                    } else {
                      handleClickUnstaking(selectedNFTItem);
                      resetSelectedNFTItem();
                      handleClickOpenStakingModal();
                    }
                  }
                }}
              >
                {modalType}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
