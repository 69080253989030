/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ReactElement, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  walletAddressState,
  walletTypeState,
  WALLET_TYPE,
} from "../../atom/wallet_address";
import ggnz_logo from "../../img/ggnz-logo.png";
import kikas_logo from "../../img/kikas.png";
import klip_logo from "../../img/klip.png";
import ConnectModal from "../modal/ConnectModal";

interface HeaderProps {
  handleClickConnectWallet: (
    walletType: WALLET_TYPE,
    walletAccount: string
  ) => void;
  account: string | null;
  snackbarMessage: string;
}

const layoutHeader = css`
  width: 100%;
  position: fixed;
  left: 0;
  top: 0px;
  z-index: 998;
  height: 80px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding-top: 20px;
  background-color: #7bc8f3;

  a {
    text-decoration: none;
  }
`;

const layoutNav = css`
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ItemNav = (path: string, currPath: string) => css`
  font-family: "Baloo 2";

  font-weight: 800;
  font-size: 24px;
  line-height: 160%;

  letter-spacing: -0.03em;

  font-style: normal;

  text-align: center;
  color: ${path === currPath ? "#009B5C" : "#FFFFFF"};
`;

const buttonContainer = css`
  display: flex;
  align-items: center;
`;

const buttonloginSuccess = css`
  height: 45px;
  background: #fcf38f;
  border-radius: 100px;

  font-family: "Baloo 2";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 178%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #181617;
  margin-right: 15px;
  padding: 10px 37px;
`;

const buttonLoggedIn = css`
  display: flex;
  border: 1px solid #afafaf;
  border-radius: 15px;
  height: 45px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  gap: 8px;
  background-color: #ffffff;
  color: black;
  width: 200px;
  .kikas-logo {
    width: 28px;
    height: 28px;
  }
  .account {
    padding-top: 2px;
    font-family: "Baloo 2";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #000000;
  }
`;

const buttonLogin = css`
  position: relative;
  height: 45px;
  .button-background {
    position: absolute;
    width: 202px;
    height: 42px;
    background-color: #000;
    border-radius: 15px;
    bottom: 1px;
    z-index: -1;
  }
  .connect-wallet {
    outline: none;
    background-color: #009b5c;
    color: white;
    font-family: "Baloo 2";
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 4px;
    width: 202px;
    height: 42px;
    border-radius: 15px;
    border: solid 3px #000;
    box-shadow: inset 0px -3px 2px 1px #0a5436;
    cursor: pointer;

    :hover {
      margin-top: 2px;
      box-shadow: inset 0px -1px 2px 1px #0a5436;
    }
  }
`;

const Header = ({
  account,
  snackbarMessage,
  handleClickConnectWallet,
}: HeaderProps): ReactElement => {
  const walletType = useRecoilValue(walletTypeState);
  const [showModal, setShowModal] = useState<boolean>(false);

  const location = useLocation();

  const currPath = location.pathname;

  const handleClickChangModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      {showModal && (
        <ConnectModal
          handleClickChangModal={handleClickChangModal}
          handleClickConnectWallet={handleClickConnectWallet}
        />
      )}

      <div css={layoutHeader}>
        <nav css={layoutNav}>
          <figure className="logo-wrap">
            <a href="http://ggnz.io" className="flex-s--c">
              <img
                width={67}
                height={61}
                className="logo-img"
                src={ggnz_logo}
                alt="개구니즈 로고 이미지"
              />
            </a>
          </figure>
          <Link to="/">
            <div css={ItemNav("/", currPath)}>SWAP</div>
          </Link>

          <Link to="staking">
            <div css={ItemNav("/staking", currPath)}>STAKING</div>
          </Link>
        </nav>
        {account ? (
          <div css={buttonContainer}>
            {snackbarMessage && (
              <div css={buttonloginSuccess}>{snackbarMessage}</div>
            )}
            <div css={buttonLoggedIn}>
              {walletType === WALLET_TYPE.KAIKAS ? (
                <img src={kikas_logo} alt="kikas logo" className="kikas-logo" />
              ) : (
                <img
                  src={klip_logo}
                  width={20}
                  alt="klip logo"
                  className="klip-logo"
                />
              )}
              <span className="account">{account.slice(0, 15)}</span>
            </div>
          </div>
        ) : (
          <div css={buttonLogin}>
            <div className="button-background" />
            <button className="connect-wallet" onClick={handleClickChangModal}>
              CONNECT WALLET
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
