export enum STAKING_STATUS {
  READY = "ready",
  PENDING = "pending",
}

export enum SWAP_STATUS {
  READY = "ready",
  PENDING = "pending",
}

export enum UNSTAKED_MODAL_TYPE {
  CLAIM = "CLAIM",
  UNSTAKE = "UNSTAKE",
}
export enum TOKEN_TYPE {
  KLAY = "KLAY",
  GGNZ = "GGNZ",
}
export enum NFT_TYPE {
  GOG = "GOG",
  GOP = "GOP",
}
