/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ReactElement, useEffect, useState } from "react";
import { klipAuth, getKlipResult } from "../../utils/klipUtils/auth";
import QRCode from "qrcode";
import { useSetRecoilState } from "recoil";
import { walletTypeState, WALLET_TYPE } from "../../atom/wallet_address";

import kikas_img from "../../img/kikas.png";
import klip_img from "../../img/klip.png";
import { signInWithKaikas } from "../../utils/signInUtils";
import CountDown from "../../utils/CountDown";

const swapModalWrapper = css`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  .complete {
    .go-opensea-area {
      position: relative;
      height: 84px;
      margin: 50px auto 0;

      .button-background {
        position: absolute;
        width: calc(100% - 60px);
        background-color: #000;
        border-radius: 25px;
        z-index: -1;
        bottom: -3px;
        height: 80px;
        left: 50%;
        transform: translateX(-50%);
      }
      .go-opensea-button {
        width: calc(100% - 60px);
        border-radius: 25px;
        height: 80px;
        font-family: "Baloo 2";
        font-weight: 800;
        font-size: 22px;
        padding-bottom: 3px;
        cursor: pointer;
        color: #fff;
        background-color: #009b5c;
        box-shadow: 0px -6px 2px 1px #0a5436 inset;
        border: solid 3px #000;
        display: block;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .go-back {
      border: none;
      font-family: "Baloo 2";
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      color: #000;
      text-align: center;
      width: calc(100% - 60px);
      background: none;
      height: 80px;
      display: block;
      margin: 0 auto;
      margin-top: 8px;
      cursor: pointer;
    }
  }
`;

const layoutModal = (isKlip: boolean) => css`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${isKlip ? "#1C6FF1" : "#ffffff"};
  z-index: 200;
  position: absolute;
  width: 600px;
  height: 360px;
  border-radius: 35px;
  padding: 30px;
  color: black;
  font-family: "Baloo 2";
  font-style: normal;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const title = css`
  text-align: left;
  font-size: 30px;
`;

interface SwapModapProps {
  handleClickChangModal: () => void;
  handleClickConnectWallet: (
    walletType: WALLET_TYPE,
    walletAccount: string
  ) => void;
}

export default function ConnectModal({
  handleClickChangModal,
  handleClickConnectWallet,
}: SwapModapProps): ReactElement {
  const [showKlip, setShowKlip] = useState<boolean>(false);
  const [resKey, setResKey] = useState<string>();
  const [codeQr, setCodeQr] = useState<string>();
  const [trySignIn, setTrySignIn] = useState<boolean>(false);
  const [timerStart, setTimerStart] = useState<boolean>(false);
  const [timerDone, setTimerDone] = useState<boolean>(false);
  const setWalletType = useSetRecoilState(walletTypeState);
  const [intervalId, setIntervalId] = useState<number>(0);

  const [isKlip, setIsKlip] = useState<boolean>(false);
  const kaikasLogin = () => {
    signInWithKaikas().then((data) => {
      handleClickConnectWallet(WALLET_TYPE.KAIKAS, data);
    });

    setWalletType(WALLET_TYPE.KAIKAS);
    handleClickChangModal();
  };

  useEffect(() => {
    if (showKlip) {
      klipAuth().then((result) => {
        setTimerStart(true);
        setResKey(result.requestKey);
        QRCode.toDataURL(
          "https://klipwallet.com/?target=/a2a?request_key=" + result.requestKey
        ).then((url) => {
          setCodeQr(url);
        });
      });
    }
  }, [showKlip]);

  useEffect(() => {
    if (timerStart && !timerDone) {
      getKlipResult(resKey).then((data) => {
        if (data.status !== "completed") {
          setTimeout(() => {
            setTrySignIn((prev) => !prev);
          }, 1000);
        } else {
          if (data.result.klaytn_address) {
            const account = data.result.klaytn_address;
            handleClickConnectWallet(WALLET_TYPE.KLIP, account);
            setWalletType(WALLET_TYPE.KLIP);
          }
          setTimerDone(true);
        }
      });
    }
  }, [timerStart, timerDone, trySignIn]);

  const clickShowKlip = () => {
    setIsKlip(true);
    setShowKlip(true);
  };

  useEffect(() => {
    if (timerDone) {
      handleClickChangModal();
    }
  }, [timerDone]);
  return (
    <div css={swapModalWrapper} onClick={handleClickChangModal}>
      <div
        css={layoutModal(isKlip)}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {!showKlip && (
          <div>
            <div css={title}>Connect Wallet</div>
            <div
              css={css`
                margin-top: 62px;
                margin-bottom: 23px;
                cursor: pointer;

                font-size: 20px;
                display: flex;
                align-items: center;
                width: 540px;
                height: 80px;
                border-radius: 25px;
                background-color: #1c6ff1;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
              `}
              onClick={clickShowKlip}
            >
              <span
                css={css`
                  padding-top: 8px;
                  margin-right: 10px;
                `}
              >
                <img width={25} src={klip_img} />
              </span>
              <span
                css={css`
                  font-family: "CookieRun 400";
                  font-weight: 100;
                `}
              >
                Klip 지갑 연결
              </span>
            </div>

            <div
              css={css`
                cursor: pointer;

                font-size: 20px;
                display: flex;
                align-items: center;
                width: 540px;
                height: 80px;
                border-radius: 25px;
                background-color: #eaeaea;
                display: flex;
                justify-content: center;
                align-items: center;
              `}
              onClick={kaikasLogin}
            >
              <span
                css={css`
                  padding-top: 9px;
                  margin-right: 10px;
                `}
              >
                <img width={25} src={kikas_img} />
              </span>
              <span
                css={css`
                  font-family: "CookieRun 400";
                  font-weight: 100;
                `}
              >
                카이카스 지갑 연결
              </span>
            </div>
          </div>
        )}

        {showKlip && codeQr && (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                font-size: 22px;
                line-height: 160%;
                /* or 35px */

                text-align: center;

                color: #ffffff;
              `}
            >
              <span
                css={css`
                  padding-top: 8px;
                  margin-right: 10px;
                `}
              >
                <img width={25} src={klip_img} />
              </span>
              <span
                css={css`
                  font-family: "CookieRun 400";
                  font-weight: 100;
                `}
              >
                Klip QR 연결
              </span>
            </div>
            <div
              css={css`
                margin: 20px 0px;
              `}
            >
              <img src={codeQr} width={150} />
            </div>
            <div
              css={css`
                font-family: "CookieRun 400";
                display: flex;
                justify-content: center;

                font-size: 16px;
                line-height: 160%;
                /* identical to box height, or 26px */
                font-weight: 100;
                text-align: center;

                color: #eaeaea;
              `}
            >
              <span>남은 시간</span>
              {timerStart && <CountDown minNum={5} setDone={setTimerDone} />}
            </div>
            <div
              css={css`
                font-size: 16px;
                line-height: 160%;
                /* identical to box height, or 26px */
                font-family: "CookieRun 400";
                font-weight: 100;
                text-align: center;

                color: #eaeaea;
              `}
            >
              QR코드 리더기 또는 카카오톡 앱으로 QR코드를 스캔해 주세요
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
