/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useEffect, useRef, useState } from "react";

export default function CountDown({ minNum, setDone }) {
  const [min, setMin] = useState(minNum);
  const [sec, setSec] = useState(0);
  const time = useRef(minNum * 60);
  const timerId = useRef(null);

  useEffect(() => {
    timerId.current = setInterval(() => {
      setMin(parseInt(time.current / 60));
      setSec(time.current % 60);
      time.current -= 1;
    }, 1000);

    return () => clearInterval(timerId.current);
  }, []);

  useEffect(() => {
    if (time.current <= 0) {
      setDone(true);
      clearInterval(timerId.current);
    }
  }, [sec]);

  return (
    <div
      css={css`
        margin-left: 5px;
      `}
    >
      {min > 0 && min + "분"} {sec === 0 ? "00" : sec}초
    </div>
  );
}
