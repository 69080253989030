/** @jsxImportSource @emotion/react */
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { css } from "@emotion/react";

import {
  InitialKaikasInfoSchema,
  SwapItemSchema,
  SwapRateInfoSchema,
} from "../../../constants/schema/initial_state_schema";

import ggnz_char from "../../../img/ggnz_char.png";

import SwapRate from "./SwapRate";
import SlippageRate from "../SlippageRate";
import SwapExchangeBoxes from "./SwapExchangeBoxes";

import SwapModal from "../../modal/SwapModal";
import { SWAP_STATUS, TOKEN_TYPE } from "../../../constants/enums/enum";
import { getCalculatedReservedRate } from "../../../utils/utils";
import { throttle } from "lodash";
import {
  getCalculatedTransferAmount,
  getCalculateFee,
} from "../../../utils/swap_func";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";

const layoutSwapContainer = (isOverThanThousand: boolean) => css`
  margin-top: ${isOverThanThousand ? 0 : 200}px;
  width: 100%;
  height: ${isOverThanThousand ? "672px" : "100%"};
`;

const title = css`
  font-size: 30px;
`;

const colorGray = css`
  color: #afafaf;
`;

const fonts = css`
  font-family: "Baloo 2";
  font-style: normal;
  font-weight: 800;
`;

const boxSwap = css`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 2px solid #000000;

  border-radius: 35px;

  padding: 27px 30px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const swapButtonWrapper = (isOverThanFiveHundred: boolean) => css`
  width: 100%;
  max-width: 600px;
  position: relative;
  height: 65px;

  margin-top: ${isOverThanFiveHundred ? 0 : 50}px;
  .mint-button-background {
    position: absolute;
    width: 100%;
    background-color: #515151;
    border-radius: 25px;
    z-index: -1;
    bottom: -3px;
    height: 60px;

    &.active {
      background-color: #000;
    }
  }
  .mint-button {
    width: 100%;
    background-color: #eaeaea;
    border-radius: 25px;
    box-shadow: inset 0px -6px 2px 1px #afafaf;
    height: 65px;
    border: solid 3px #515151;
    font-family: "Baloo 2";
    font-weight: 800;
    font-size: 31px;
    color: #afafaf;
    padding-bottom: 3px;
    cursor: pointer;

    &.active {
      background-color: #009b5c;
      box-shadow: 0px -6px 2px 1px #0a5436 inset;
      border: solid 3px #000;
      color: #fff;
      :hover {
        margin-top: 5px;
        box-shadow: inset 0px -1px 2px 1px #0a5436;
      }
    }
  }
`;

const swapBottomWrapper = (isOverThanThousand: boolean) => css`
  padding-top: 26px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${isOverThanThousand ? "row" : "column"};
  align-items: ${isOverThanThousand ? "space-between" : "center"};
  justify-content: space-between;
`;

const swapImageWrapper = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const swapImage = css`
  margin-bottom: -20px;
`;

const swapBottomRight = css`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
interface SwapContainerProps {
  info: InitialKaikasInfoSchema;
  swapStatus: SWAP_STATUS;
  setSwapSatus: Dispatch<SetStateAction<SWAP_STATUS>>;

  swapRateInfos: SwapRateInfoSchema;
  isConnected: boolean;
  handleClickSwap: (toGGNZ: boolean, amount: number) => void;

  swapItems: [SwapItemSchema, SwapItemSchema];
  setSwapItems: Dispatch<SetStateAction<[SwapItemSchema, SwapItemSchema]>>;

  showTransactionModal: boolean;
  handleChangeSwapModal: (status: boolean) => void;
}
export default function SwapContainer({
  info,
  swapStatus,
  swapRateInfos,
  isConnected,
  handleClickSwap,
  swapItems,
  setSwapItems,
  showTransactionModal,
  handleChangeSwapModal,
}: SwapContainerProps): ReactElement {
  const [windowWidth] = useWindowSize();
  const [fromData, toData] = swapItems;

  const isOverThanThousand = windowWidth > 1000;
  const isOverThanFiveHundred = windowWidth > 500;
  const isSwapAvailable =
    isConnected &&
    swapItems[0].amount > 0 &&
    swapItems[0].balance >= swapItems[0].amount &&
    swapStatus === SWAP_STATUS.READY;

  const isInputLessThanBalance =
    isConnected && fromData.amount <= fromData.balance;

  const [calculatedFee, setCalculatedFee] = useState<number>(0);
  const [calculatedAmount, setCalculatedAmount] = useState<number>(0);
  const [exchangeRate, setExchangeRate] = useState<number>(0);

  const throttled = useCallback(
    throttle((amount: number, type: string) => {
      const isToGGNZ = type === TOKEN_TYPE.KLAY;

      Promise.all([
        getCalculateFee(
          info.SWAPContract,
          info.caver,
          info.account,
          isToGGNZ,
          amount
        ),
        getCalculatedTransferAmount(
          info.SWAPContract,
          info.caver,
          info.account,
          isToGGNZ,
          amount
        ),
      ]).then((data) => {
        setCalculatedFee(data[0]);
        setCalculatedAmount(data[1]);
      });
    }, 2000),
    [info]
  );

  useEffect(() => {
    if (isInputLessThanBalance && fromData.amount > 0) {
      return throttled(fromData.amount, fromData.type);
    }
  }, [isInputLessThanBalance, fromData.amount, fromData.type]);

  useEffect(() => {
    const _exchangeRate = getCalculatedReservedRate(
      fromData.type === TOKEN_TYPE.KLAY,
      swapRateInfos.coinReserve || 0,
      swapRateInfos.tokenReserve || 0
    );

    setExchangeRate(_exchangeRate);
  }, [fromData.type, swapRateInfos.coinReserve, swapRateInfos.tokenReserve]);

  return (
    <>
      {showTransactionModal && (
        <SwapModal
          exchangeRate={exchangeRate}
          calculatedAmount={calculatedAmount}
          calculatedFee={calculatedFee}
          swapItems={swapItems}
          handleClickSwap={handleClickSwap}
          handleChangeSwapModal={handleChangeSwapModal}
        />
      )}

      <div css={layoutSwapContainer(isOverThanThousand)}>
        <div css={boxSwap}>
          <div
            css={swapImage}
            style={{
              textAlign: "center",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img width={374} height={170} src={ggnz_char} alt="ggnz" />
            <div css={[fonts, title, colorGray]}>Coming Soon</div>
          </div>
          {/* <div>
            <SlippageRate slippageRate={0.5} />
            <SwapExchangeBoxes
              calculatedAmount={calculatedAmount}
              swapItems={swapItems}
              setSwapItems={setSwapItems}
              swapRateInfos={swapRateInfos}
            />
          </div>
          SwapExchangeBoxes
          <div css={swapBottomWrapper(isOverThanThousand)}>
            {windowWidth > 1000 && (
              <div css={swapImageWrapper}>
                <div css={swapImage}>
                  <img width={536} height={242} src={ggnz_char} alt="ggnz" />
                </div>
              </div>
            )}

            <div css={swapBottomRight}>
              <SwapRate
                toDataType={toData.type}
                exchangeRate={exchangeRate}
                isInputLessThanBalance={isInputLessThanBalance}
                calculatedAmount={calculatedAmount}
                calculatedFee={calculatedFee}
                isConnected={isConnected}
              />
              <div css={swapButtonWrapper(isOverThanThousand)}>
                <div
                  className={`mint-button-background ${
                    isSwapAvailable ? "active" : ""
                  }`}
                />
                <button
                  className={`mint-button ${isSwapAvailable ? "active" : ""}`}
                  onClick={() => {
                    if (isSwapAvailable) {
                      handleChangeSwapModal(true);
                    }
                  }}
                >
                  {swapStatus === SWAP_STATUS.READY ? "SWAP" : "PENDING"}
                </button>
              </div>
            </div>
            {windowWidth <= 1000 && (
              <div css={swapImageWrapper}>
                <div css={swapImage}>
                  <img
                    width={536 * (isOverThanFiveHundred ? 1 : 0.8)}
                    height={242 * (isOverThanFiveHundred ? 1 : 0.8)}
                    src={ggnz_char}
                    alt="ggnz"
                  />
                </div>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
}
