/** @jsxImportSource @emotion/react */
import { Dispatch, ReactElement, SetStateAction } from "react";

import { css } from "@emotion/react";

import { STAKING_TAB } from "./StakingBox";

import StakingTab from "./StakingTab";

import {
  STAKING_STATUS,
  UNSTAKED_MODAL_TYPE,
} from "../../../constants/enums/enum";

import StakingItemsBox from "./StakingItemsBox";
import StakedNFTTabInfoBox from "./StakedNFTTabInfoBox";

import MyWalletTabStakingButton from "./MyWalletTabStakingButton";
import {
  InitialKaikasInfoSchema,
  MyOwnNFTInfoSchema,
} from "../../../constants/schema/initial_state_schema";
import { useRecoilValue } from "recoil";
import { recoilTradeState, TRADE_STATE } from "../../../atom/atom";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";

const stakingBoxWrapper = (windowWidth: number) => css`
  width: 100%;
  height: 672px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Baloo 2";
  padding-top: ${windowWidth > 1212 ? 0 : 150}px;
`;

const connectedBox = css`
  width: 100%;
  height: 672px;

  background: #ffffff;
  border: 2px solid #000000;
  box-shadow: 0px 4px 0px #000000;
  border-radius: 35px;

  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  padding: 34px 20px;
`;

interface ConnectedStakingInfoProps {
  info: InitialKaikasInfoSchema;
  accuredRewards: string;
  myOwnNFTInfo: MyOwnNFTInfoSchema;
  myNftArray: string[][];
  stakedNFT: string[][];

  dataOfNFT: any;

  stakingTab: STAKING_TAB;
  selectAllNFTItem: () => void;
  handleChangeStakingTab: (tab: STAKING_TAB) => void;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  resetSelectedNFTItem: () => void;
  selectedNFTItem: string[];
  handleChangeSelectedNFTItem: (items: string[]) => void;
  stakingStatus: STAKING_STATUS;

  handleClickOpenStakingModal: () => void;

  handleClickStaking: (selectedItems: string[]) => void;

  handleChangeModalType: (type: UNSTAKED_MODAL_TYPE) => void;
  getStakingQrAddress: (selectedItems: string[]) => Promise<string>;
  updateInfoAfterStaking: (selectedItems: string[], result: boolean) => void;
}

export default function ConnectedStakingInfo({
  info,
  accuredRewards,
  myOwnNFTInfo,
  myNftArray,
  stakedNFT,

  dataOfNFT,

  stakingTab,
  selectAllNFTItem,
  handleChangeStakingTab,
  page,
  setPage,
  resetSelectedNFTItem,
  selectedNFTItem,
  handleChangeSelectedNFTItem,
  handleClickOpenStakingModal,
  stakingStatus,

  handleClickStaking,
  handleChangeModalType,
  getStakingQrAddress,
  updateInfoAfterStaking,
}: ConnectedStakingInfoProps): ReactElement {
  const [windowWidth] = useWindowSize();

  const stakingButtonAvailable =
    stakingTab === STAKING_TAB.MY_WALLET && selectedNFTItem.length > 0;

  const claimOrUnstakeButtonAvailable =
    stakingTab === STAKING_TAB.STAKED_NFT && selectedNFTItem.length > 0;

  const recoilTradeStatus = useRecoilValue(recoilTradeState);

  return (
    <div css={stakingBoxWrapper(windowWidth)}>
      <div css={connectedBox}>
        <div
          css={css`
            padding: 0px 30px;
          `}
        >
          <StakingTab
            isConnected={true}
            stakingTab={stakingTab}
            onChangeStakingTab={handleChangeStakingTab}
            myWalletCount={myOwnNFTInfo.unstakedNFT.length}
            stakedNftCount={myOwnNFTInfo.stakedNFT.length}
            setPage={setPage}
            resetSelectedNFTItem={resetSelectedNFTItem}
            accuredRewards={accuredRewards}
          />
        </div>
        <StakingItemsBox
          info={info}
          stakingTab={stakingTab}
          myWalletNFT={myNftArray}
          stakedNFT={stakedNFT}
          dataOfNFT={dataOfNFT}
          page={page}
          setPage={setPage}
          selectedNFTItem={selectedNFTItem}
          handleChangeSelectedNFTItem={handleChangeSelectedNFTItem}
          resetSelectedNFTItem={resetSelectedNFTItem}
          selectAllNFTItem={selectAllNFTItem}
        />

        {stakingTab === STAKING_TAB.STAKED_NFT &&
          recoilTradeStatus === TRADE_STATE.PENDING && (
            <StakedNFTTabInfoBox
              info={info}
              stakedNFT={stakedNFT}
              claimOrUnstakeButtonAvailable={claimOrUnstakeButtonAvailable}
              handleClickOpenStakingModal={handleClickOpenStakingModal}
              handleChangeModalType={handleChangeModalType}
            />
          )}

        {stakingTab === STAKING_TAB.MY_WALLET && (
          <MyWalletTabStakingButton
            selectedNFTItem={selectedNFTItem}
            stakingButtonAvailable={stakingButtonAvailable}
            stakingStatus={stakingStatus}
            handleClickStaking={handleClickStaking}
            getStakingQrAddress={getStakingQrAddress}
            updateInfoAfterStaking={updateInfoAfterStaking}
            resetSelectedNFTItem={resetSelectedNFTItem}
            handleClickOpenStakingModal={handleClickOpenStakingModal}
          />
        )}
      </div>
    </div>
  );
}
