/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Dispatch, ReactElement, SetStateAction } from "react";
import { STAKING_TAB } from "./StakingBox";
import StakingTab from "./StakingTab";

import staking_connect_wallet from "../../../img/staking_connect_wallet.png";

const disConnectedBox = css`
  width: 100%;
  height: 672px;

  background: #ffffff;
  border: 2px solid #000000;
  box-shadow: 0px 4px 0px #000000;
  border-radius: 35px;

  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  padding: 34px 55px;
`;

const textConnectWallet = css`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #afafaf;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
`;

const ggnzImageBox = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface DisConnectedStakingInfoProps {
  stakingTab: STAKING_TAB;
  handleChangeStakingTab: (tab: STAKING_TAB) => void;
  setPage: Dispatch<SetStateAction<number>>;
  resetSelectedNFTItem: () => void;
}

export default function DisConnectedStakingInfo({
  stakingTab,
  handleChangeStakingTab,
  setPage,
  resetSelectedNFTItem,
}: DisConnectedStakingInfoProps): ReactElement {
  return (
    <div css={disConnectedBox}>
      <StakingTab
        isConnected={false}
        stakingTab={stakingTab}
        onChangeStakingTab={handleChangeStakingTab}
        myWalletCount={0}
        stakedNftCount={0}
        setPage={setPage}
        resetSelectedNFTItem={resetSelectedNFTItem}
      />
      <div css={ggnzImageBox}>
        <img src={staking_connect_wallet} width={160} height={160} />
        <div css={textConnectWallet}>Connect your Wallet</div>
      </div>
    </div>
  );
}
