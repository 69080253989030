import { atom } from "recoil";

export enum TRADE_STATE {
  PENDING = "pending",
  REFRESH = "refersh",
}

export const recoilTradeState = atom<TRADE_STATE>({
  key: "tradeState",
  default: TRADE_STATE.PENDING,
});
