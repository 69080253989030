export default function ItemChecked(): JSX.Element {
  return (
    <svg
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7072 0.292893C11.0977 0.683417 11.0977 1.31658 10.7072 1.70711L4.70717 7.70711C4.50218 7.91209 4.21833 8.01803 3.92917 7.99748C3.64001 7.97693 3.37399 7.83191 3.20006 7.6L0.200059 3.6C-0.131312 3.15817 -0.0417689 2.53137 0.400059 2.2C0.841887 1.86863 1.46869 1.95817 1.80006 2.4L4.10825 5.47759L9.29295 0.292893C9.68348 -0.0976311 10.3166 -0.0976311 10.7072 0.292893Z"
        fill="white"
      />
    </svg>
  );
}
