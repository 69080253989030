/* eslint-disable */
import axios from "axios";
import Caver, { AbiItem, Contract } from "caver-js";
import { NFT_TYPE } from "../constants/enums/enum";
import { getTokenURISchema } from "../constants/schema/return_value_schema";
import StakingContract from "../contracts/abis/Staking.json";

// const urls = [
//   "https://klaytn-mainnet-rpc.allthatnode.com:8551",
//   "https://klaytn01.fandom.finance/",
//   "https://klaytn02.fandom.finance/",
//   "https://klaytn03.fandom.finance/",
//   "https://klaytn04.fandom.finance/"
// ];

// const cav = new Caver(new Caver.providers.HttpProvider(urls[0]));
// const stakingABI: any = StakingContract.abi;
// const stakeContract = new cav.klay.Contract(stakingABI, StakingContract.contractAddress);

interface contractMethodCallSchema {
  contract?: Contract;
  method: string;
  params: any;
}

interface contractMethodSendSchema {
  contract?: Contract;
  method: string;
  params: any;
  account: string | null;
}

const contractMethodCall = async ({ contract, method, params }: contractMethodCallSchema) => {
  if (contract === undefined) return;
  return await contract.methods[method](...params).call();
};

const contractMethodSend = async ({ contract, method, params, account }: contractMethodSendSchema): Promise<any> => {
  const gas = await contract?.methods[method](...params).estimateGas({
    from: account,
  });
  return contract?.methods[method](...params).send({
    from: account,
    gas: gas > 1500000 ? gas : 1500000,
  });
};

interface getNFTIdsSchema {
  version?: number;
  contract?: Contract;
  account: string | null;
}

export interface getStakingContractMehodsSchema {
  contract?: Contract;
  account: string | null;
  ids: string[];
  version?: number;
}

export interface getAccuredRewardsScema {
  contract?: Contract;
  account: string | null;
}

export const getTokenURI = async (id: number, contract?: Contract): Promise<string> => {
  return await contractMethodCall({
    contract,
    method: "tokenURI",
    params: [id],
  });
};

export const getTokensURI = async ({ contract, ids }: getStakingContractMehodsSchema): Promise<string[]> => {
  return Promise.all(
    ids.map(async (x) => {
      const [_version, id] = x.split(" ");
      const result = await getTokenURI(Number(id), contract);
      return result;
    }),
  );
};

const fetchAllTokenURI = async (arr: any[]): Promise<any> => {
  return Promise.all(
    arr.map((x) => {
      const result: any = axios.get(x);
      return result;
    }),
  ).then((resArr) => resArr.map((x) => x.data));
};

export const parseTokensURI = async (version: number, arr: any[]): Promise<any> => {
  const result: any[] = [];
  await fetchAllTokenURI(arr).then((res) => {
    res.forEach(async (x: any) => {
      result.push(await x);
    });
  });
  return Promise.all(result).then((data) => {
    return data;
  });
  // return result.map((x) => {
  //   return {
  //     ...x,
  //     version,
  //   }
  // });
  // .then(data => {
  //   return data.map(x => {
  //     // const nameSplit = x.name.split(" ");
  //     // const tokenId = nameSplit.slice(1);
  //     return ({
  //       ... x,
  //     version,
  //     // id: version === 1 ? NFT_TYPE.GOG + " " + tokenId : NFT_TYPE.GOP + " " + tokenId,
  //     // numId: Number(tokenId),
  //     });
  // });
  // });
};

export const getNFTIds = async ({ version, contract, account }: getNFTIdsSchema): Promise<string[]> => {
  // NOTE - NFT ID list 불러오기
  const maxNums = Number(
    (await contractMethodCall({
      contract,
      method: "balanceOf",
      params: [account],
    })) ?? 0,
  );
  const promises = [];
  for (let i = 0; i < maxNums; i++) {
    promises.push(
      contractMethodCall({
        contract,
        method: "tokenOfOwnerByIndex",
        params: [account, i],
      }),
    );
  }
  return Promise.all(promises).then((data) => data.map((x) => (version === 1 ? "GOG " + x : "GOP " + x)));
};

export const getStakedNFT = async ({ contract, ids, version }: getStakingContractMehodsSchema): Promise<string[]> => {
  const prefix = version === 1 ? NFT_TYPE.GOG : NFT_TYPE.GOP;
  const result = [];

  for (let versionAndId of ids) {
    const id = Number(versionAndId.split(" ")[1]);

    const isStaked = await contractMethodCall({
      contract,
      method: "getIsStakedStatus",
      params: [id],
    });

    if (Number(isStaked)) {
      result.push(`${prefix} ${id}`);
    }
  }

  return result;
};

export const getAccuredRewards = async ({ contract, account }: getAccuredRewardsScema): Promise<string> => {
  // NOTE - AccuredRewards 불러오기
  return await contractMethodCall({
    contract,
    method: "getAccuredRewards",
    params: [account],
  });
};

export const getAllClaimedValue = async (v1: getStakingContractMehodsSchema, v2: getStakingContractMehodsSchema): Promise<string> => {
  return Promise.all([getAccuredRewards({ contract: v1.contract, account: v1.account }), getAccuredRewards({ contract: v2.contract, account: v2.account })])
    .then((data) => Caver.utils.toBN(data[0]).add(Caver.utils.toBN(data[1])))
    .then((sum) => sum.toString());
  // .then((sum) => Caver.utils.fromPeb(sum))
  // .then((result) => Number(result).toFixed(6));
};

export const staking = async (v1: getStakingContractMehodsSchema, v2: getStakingContractMehodsSchema, contract?: Contract): Promise<boolean> => {
  const v1Ids = v1.ids.map((x) => Number(x.split(" ")[1]));
  const v2Ids = v2.ids.map((x) => Number(x.split(" ")[1]));
  return await contractMethodSend({
    contract,
    method: "stakeV1V2V3",
    params: [v1Ids, v2Ids, []],
    account: v1.account,
  });
};

export const unstaking = async (v1: getStakingContractMehodsSchema, v2: getStakingContractMehodsSchema, contract?: Contract): Promise<boolean> => {
  const v1Ids = v1.ids.map((x) => Number(x.split(" ")[1]));
  const v2Ids = v2.ids.map((x) => Number(x.split(" ")[1]));
  return await contractMethodSend({
    contract,
    method: "unStakeV1V2V3",
    params: [v1Ids, v2Ids, []],
    account: v1.account,
  });
};

export const claim = async (v1: getStakingContractMehodsSchema, v2: getStakingContractMehodsSchema, contract?: Contract): Promise<boolean> => {
  const v1Ids = v1.ids.map((x) => Number(x.split(" ")[1]));
  const v2Ids = v2.ids.map((x) => Number(x.split(" ")[1]));
  return await contractMethodSend({
    contract,
    method: "claimRewardV1V2V3",
    params: [v1Ids, v2Ids, []],
    account: v1.account,
  });
};

export const getExpactedClaimableAmount = async (
  selcetedNFTItems: string[],
  contract?: Contract,
  allofNFT?: string[],
  interval?: NodeJS.Timer,
): Promise<any> => {
  if (allofNFT && interval) {
    if (selcetedNFTItems.length === 1 && !allofNFT.includes(selcetedNFTItems[0])) {
      return clearInterval(interval);
    }
  }

  const tmp = selcetedNFTItems.map((x) => x.split(" "));

  const promises = [];
  for (const [_version, id] of tmp) {
    const version = _version === NFT_TYPE.GOG ? 1 : 2;
    promises.push(
      contractMethodCall({
        contract,
        // contract: stakeContract,
        method: "calculateClaimableReward",
        params: [version, id],
      }),
    );
  }

  return Promise.all(promises)

    .then((data) => data.map((x) => Caver.utils.toBN(x)))
    .then((data) => data.reduce((a, c) => a.add(c)))
    .then((sum) => sum.toString())
    .catch((err) => {
      return "0";
    });

  // .then((sum) => Caver.utils.fromPeb(sum))
  // .then((result) => Number(result).toFixed(6));
};

export async function getNFTPhoto(url: string): Promise<getTokenURISchema> {
  try {
    const result: any = await axios.get(url);
    return result.data;
  } catch (err) {
    const temp = {
      name: "err",
      description: "err",
      image: "err",
      edition: 0,
      date: "err",
      external_url: "err",
    } as getTokenURISchema;
    return temp;
  }
}

export const getIsStakedStatus = async (id: number, contract?: Contract): Promise<string> => {
  return await contractMethodCall({
    contract,
    method: "getIsStakedStatus",
    params: [id],
  });
};
