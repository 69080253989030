/** @jsxImportSource @emotion/react */

import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";

import {
  STAKING_STATUS,
  UNSTAKED_MODAL_TYPE,
} from "../../../constants/enums/enum";

import DisConnectedStakingInfo from "./DisconnectedStakingInfo";
import ConnectedStakingInfo from "./ConnectedStakingInfo";
import StakingModal from "../../modal/StakingModal";
import {
  InitialKaikasInfoSchema,
  MyOwnNFTInfoSchema,
} from "../../../constants/schema/initial_state_schema";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";

interface StakingBoxProps {
  isConnected: boolean;
  info: InitialKaikasInfoSchema;

  stakingStatus: STAKING_STATUS;

  myOwnNFTInfo: MyOwnNFTInfoSchema;
  accuredRewards: string;

  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  dataOfNFT: any;

  handleClickStaking: (selectedItems: string[]) => void;
  handleClickClaim: (selectedItems: string[]) => void;
  getStakingQrAddress: (selectedItems: string[]) => Promise<string>;
  getClaimQrAddress: (selectedItems: string[]) => Promise<string>;
  getUnstakingQrAddress: (selectedItems: string[]) => Promise<string>;
  updateInfoAfterKlipClaim: (selectedItems: string[], result: boolean) => void;
  updateInfoAFterKlipUnstaking: (
    selectedItems: string[],
    result: boolean
  ) => void;

  updateInfoAfterStaking: (selectedItems: string[], result: boolean) => void;

  handleClickUnstaking: (selectedItems: string[]) => void;
}

export enum STAKING_TAB {
  MY_WALLET = "my_wallet",
  STAKED_NFT = "staked_nft",
}

export default function StakingBox({
  isConnected,
  info,

  stakingStatus,

  myOwnNFTInfo,
  accuredRewards,
  dataOfNFT,
  page,
  setPage,

  handleClickStaking,
  handleClickClaim,
  getStakingQrAddress,
  getClaimQrAddress,
  getUnstakingQrAddress,
  updateInfoAfterKlipClaim,
  updateInfoAFterKlipUnstaking,
  updateInfoAfterStaking,
  handleClickUnstaking,
}: StakingBoxProps): ReactElement {
  const [windowWidth] = useWindowSize();
  const [showStakingModal, setShowStakingModal] = useState<boolean>(false);

  const [stakingTab, setStakingTab] = useState<STAKING_TAB>(
    STAKING_TAB.MY_WALLET
  );

  const [modalType, setModalType] = useState<UNSTAKED_MODAL_TYPE>(
    UNSTAKED_MODAL_TYPE.CLAIM
  );

  const [myNftArray, setMyNftArray] = useState<string[][]>([]);
  const [stakedNFT, setStakedNFT] = useState<string[][]>([]);

  const [selectedNFTItem, setSelectedNFTItem] = useState<string[]>([]);

  const handleClickOpenStakingModal = () => {
    setShowStakingModal((prev) => !prev);
  };

  const handleChangeSelectedNFTItem = (items: string[]) => {
    setSelectedNFTItem(items);
  };

  const handleChangeStakingTab = (tab: STAKING_TAB) => {
    setStakingTab(tab);
  };

  const handleChangeModalType = (type: UNSTAKED_MODAL_TYPE) => {
    setModalType(type);
  };

  const resetSelectedNFTItem = () => {
    setSelectedNFTItem([]);
  };

  const selectAllNFTItem = () => {
    if (stakingTab === STAKING_TAB.MY_WALLET) {
      setSelectedNFTItem(myOwnNFTInfo.unstakedNFT);
    }

    if (stakingTab === STAKING_TAB.STAKED_NFT) {
      setSelectedNFTItem(myOwnNFTInfo.stakedNFT);
    }
  };

  useEffect(() => {
    const items: string[][] = [];
    myOwnNFTInfo.unstakedNFT
      .sort((a, b) => {
        const [_versionOfA] = a.split(" ");
        const [_versionOfB] = b.split(" ");

        const versionAValue = _versionOfA === "GOG" ? 1 : 0;
        const versionBValue = _versionOfB === "GOG" ? 1 : 0;

        return versionBValue - versionAValue;
      })
      .forEach((el, index, els) => {
        if (windowWidth > 1212) {
          if (index % 5 === 0) {
            const temp = [
              el,
              els[index + 1] || "dummy",
              els[index + 2] || "dummy",
              els[index + 3] || "dummy",
              els[index + 4] || "dummy",
            ];
            items.push(temp);
          }
        } else {
          if (index % 3 === 0) {
            const temp = [
              el,
              els[index + 1] || "dummy",
              els[index + 2] || "dummy",
            ];
            items.push(temp);
          }
        }
      });
    setMyNftArray(() => items);
  }, [myOwnNFTInfo.unstakedNFT, windowWidth]);

  useEffect(() => {
    const items: string[][] = [];
    myOwnNFTInfo.stakedNFT
      .sort((a, b) => {
        const [_versionOfA] = a.split(" ");
        const [_versionOfB] = b.split(" ");

        const versionAValue = _versionOfA === "GOG" ? 1 : 0;
        const versionBValue = _versionOfB === "GOG" ? 1 : 0;

        return versionBValue - versionAValue;
      })
      .forEach((el, index, els) => {
        if (windowWidth > 1212) {
          if (index % 5 === 0) {
            const temp = [
              el,
              els[index + 1] || "dummy",
              els[index + 2] || "dummy",
              els[index + 3] || "dummy",
              els[index + 4] || "dummy",
            ];
            items.push(temp);
          }
        } else {
          if (index % 3 === 0) {
            const temp = [
              el,
              els[index + 1] || "dummy",
              els[index + 2] || "dummy",
            ];
            items.push(temp);
          }
        }
      });
    setStakedNFT(() => items);
  }, [myOwnNFTInfo.stakedNFT, windowWidth]);

  return (
    <>
      {showStakingModal && (
        <StakingModal
          info={info}
          modalType={modalType}
          accuredRewards={accuredRewards}
          selectedNFTItem={selectedNFTItem}
          handleClickClaim={handleClickClaim}
          getClaimQrAddress={getClaimQrAddress}
          getUnstakingQrAddress={getUnstakingQrAddress}
          updateInfoAfterKlipClaim={updateInfoAfterKlipClaim}
          updateInfoAFterKlipUnstaking={updateInfoAFterKlipUnstaking}
          handleClickUnstaking={handleClickUnstaking}
          resetSelectedNFTItem={resetSelectedNFTItem}
          handleClickOpenStakingModal={handleClickOpenStakingModal}
        />
      )}

      {!isConnected && (
        <DisConnectedStakingInfo
          stakingTab={stakingTab}
          handleChangeStakingTab={handleChangeStakingTab}
          setPage={setPage}
          resetSelectedNFTItem={resetSelectedNFTItem}
        />
      )}

      {isConnected && (
        <ConnectedStakingInfo
          info={info}
          accuredRewards={accuredRewards}
          myOwnNFTInfo={myOwnNFTInfo}
          dataOfNFT={dataOfNFT}
          myNftArray={myNftArray}
          stakedNFT={stakedNFT}
          stakingTab={stakingTab}
          selectAllNFTItem={selectAllNFTItem}
          handleChangeStakingTab={handleChangeStakingTab}
          page={page}
          setPage={setPage}
          resetSelectedNFTItem={resetSelectedNFTItem}
          selectedNFTItem={selectedNFTItem}
          handleChangeSelectedNFTItem={handleChangeSelectedNFTItem}
          stakingStatus={stakingStatus}
          handleClickStaking={handleClickStaking}
          getStakingQrAddress={getStakingQrAddress}
          updateInfoAfterStaking={updateInfoAfterStaking}
          handleClickOpenStakingModal={handleClickOpenStakingModal}
          handleChangeModalType={handleChangeModalType}
        />
      )}
    </>
  );
}
