import Caver from "caver-js";

declare global {
  interface Window {
    ethereum: any;
    klaytn: any;
  }
}

export async function signInWithKaikas() {
  if (window.klaytn.isKaikas) {
    try {
      const accounts = await window.klaytn.enable();

      return accounts[0];
    } catch {
      return null;
    }
  } else return null;
}
