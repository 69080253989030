import Caver from "caver-js";
import { getPebFromKlay } from "./swap_func";

export function getDividedNumber(num: number): string {
  const numToStr = String(num);
  const result = `${numToStr.slice(0, numToStr.length - 18)}.${numToStr.slice(
    numToStr.length - 18,
    numToStr.length - 14
  )}`;
  return result;
}

export function getCalculatedReservedRate(
  toGGNZ: boolean,
  coinReserve: number,
  tokenReserve: number
): number {
  if (toGGNZ) {
    return tokenReserve / coinReserve;
  } else {
    return coinReserve / tokenReserve;
  }
}

export async function increaseAllowance(
  caver: any,
  contract: any,
  amount: number,
  address: string | null,
  swapContract: any
): Promise<boolean> {
  if (contract.options.address) {
    const oldAllowance = caver.utils.toBN(
      await contract.methods
        .allowance(address, swapContract.options.address)
        .call()
    );
    try {
      const amountToPeb = getPebFromKlay(caver, String(amount));
      if (oldAllowance.sub(amountToPeb) > 0) {
        return true;
      }

      const result = await contract.methods
        .increaseAllowance(swapContract.options.address, amountToPeb.toString())
        .send({ from: address, gas: 1500000 });

      if (result) {
        return true;
      } else return false;
    } catch (err) {
      return false;
    }
  } else {
    return false;
  }
}

export const addBN = (...strBN: any[]) => {
  const BNs = strBN.map((x) => Caver.utils.toBN(x));
  const resultBN = BNs.reduce((a, c) => a.add(c));
  return resultBN.toString();
};

export const subBN = (...strBN: any[]) => {
  const BNs = strBN.map((x) => Caver.utils.toBN(x));
  const resultBN = BNs.reduce((a, c) => a.sub(c));
  return resultBN.toString();
};

export const getNumberForDisplayUser = (strBn: any) => {
  return Number(Caver.utils.fromPeb(strBn)).toFixed(6);
};
