/** @jsxImportSource @emotion/react */
import { ReactElement, useEffect, useState } from "react";
import { css } from "@emotion/react";

import {
  initalExchangeRateInfo,
  initialSwapItemsInfo,
} from "../../constants/initialState/initial_state";
import {
  InitialKaikasInfoSchema,
  SwapItemSchema,
  SwapRateInfoSchema,
} from "../../constants/schema/initial_state_schema";

import SwapContainer from "./swapContainer/SwapContainer";
import { SWAP_STATUS, TOKEN_TYPE } from "../../constants/enums/enum";
import {
  getBalanceOfGGNZ,
  getBalanceOfKLAY,
  getSWAPReserves,
  swap,
} from "../../utils/swap_func";
import { increaseAllowance } from "../../utils/utils";

const layoutSwapPage = (screenWidth: number) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100% auto;
  height: 100vh;
  padding-top: ${screenWidth > 1024 ? 50 : 300}px;

  color: white;
`;

const layoutSwap = css`
  width: 100%;
  max-width: 1320px;
  min-height: 672px;
`;

interface SwapPageProps {
  info: InitialKaikasInfoSchema;
  isConnected: boolean;
  handleChangeSnackBarMessage: (message: string) => void;
}

export default function SwapPage({
  info,
  isConnected,
  handleChangeSnackBarMessage,
}: SwapPageProps): ReactElement {
  const screenWidth = screen.width;

  const [showTransactionModal, setShowTransactionModal] =
    useState<boolean>(false);

  const [swapRateInfos, setSwapRateInfos] = useState<SwapRateInfoSchema>(
    initalExchangeRateInfo
  );

  const [swapItems, setSwapItems] =
    useState<[SwapItemSchema, SwapItemSchema]>(initialSwapItemsInfo);

  const [swapStatus, setSwapSatus] = useState<SWAP_STATUS>(SWAP_STATUS.READY);

  useEffect(() => {
    if (isConnected) {
      Promise.all([
        getBalanceOfKLAY(info.caver, info.account),
        getBalanceOfGGNZ(info.GGNZContract, info.caver, info.account),
        getSWAPReserves(info.SWAPContract, info.caver),
      ]).then((data) => {
        const [klayBalance, ggnzBalance, swapReserves] = data;

        const newData = swapItems;

        if (newData[0].type === TOKEN_TYPE.KLAY) {
          newData[0].balance = klayBalance;
          newData[1].balance = ggnzBalance;
        } else {
          newData[1].balance = klayBalance;
          newData[0].balance = ggnzBalance;
        }

        setSwapItems(newData);

        setSwapRateInfos((prev) => ({ ...prev, ...swapReserves }));
      });
    }
  }, [isConnected]);

  const handleChangeSwapModal = (status: boolean) => {
    setShowTransactionModal(status);
  };

  const handleClickSwap = async (toGGNZ: boolean, amount: number) => {
    const now = String(new Date().getTime());
    const deleteLastThreeNum = Number(now.slice(0, now.length - 3));

    setSwapSatus(SWAP_STATUS.PENDING);
    handleChangeSwapModal(false);

    let promiseApprove;
    if (!toGGNZ) {
      promiseApprove = await Promise.resolve(
        increaseAllowance(
          info.caver,
          info.GGNZContract,
          amount,
          info.account,
          info.SWAPContract
        )
      );
    }

    if (toGGNZ && !promiseApprove) {
      const promise: Promise<boolean> = Promise.resolve(
        swap(
          info.SWAPContract,
          info.caver,
          info.account,
          toGGNZ,
          amount,
          deleteLastThreeNum
        )
      );
      promise.then((result) => {
        if (result) {
          Promise.all([
            getBalanceOfKLAY(info.caver, info.account),
            getBalanceOfGGNZ(info.GGNZContract, info.caver, info.account),
            getSWAPReserves(info.SWAPContract, info.caver),
          ])
            .then((data) => {
              const [klayBalance, ggnzBalance, swapReserves] = data;

              const newData = swapItems;

              newData[0].amount = 0;
              if (newData[0].type === TOKEN_TYPE.KLAY) {
                newData[0].balance = klayBalance;
                newData[1].balance = ggnzBalance;
              } else {
                newData[1].balance = klayBalance;
                newData[0].balance = ggnzBalance;
              }

              setSwapItems(newData);

              setSwapRateInfos((prev) => ({ ...prev, ...swapReserves }));
            })
            .then(() => {
              handleChangeSnackBarMessage("Success!");
              setSwapSatus(SWAP_STATUS.READY);
            });
        }
      });
    } else if (promiseApprove) {
      const promise: Promise<boolean> = Promise.resolve(
        swap(
          info.SWAPContract,
          info.caver,
          info.account,
          toGGNZ,
          amount,
          deleteLastThreeNum
        )
      );
      promise.then((result) => {
        if (result) {
          Promise.all([
            getBalanceOfKLAY(info.caver, info.account),
            getBalanceOfGGNZ(info.GGNZContract, info.caver, info.account),
            getSWAPReserves(info.SWAPContract, info.caver),
          ])
            .then((data) => {
              const [klayBalance, ggnzBalance, swapReserves] = data;

              const newData = swapItems;

              newData[0].amount = 0;
              if (newData[0].type === TOKEN_TYPE.KLAY) {
                newData[0].balance = klayBalance;
                newData[1].balance = ggnzBalance;
              } else {
                newData[1].balance = klayBalance;
                newData[0].balance = ggnzBalance;
              }

              setSwapItems(newData);

              setSwapRateInfos((prev) => ({ ...prev, ...swapReserves }));
            })
            .then(() => {
              handleChangeSnackBarMessage("Success!");
              setSwapSatus(SWAP_STATUS.READY);
            });
        }
      });
    }
  };

  return (
    <div css={layoutSwapPage(screenWidth)}>
      <div css={layoutSwap}>
        <SwapContainer
          info={info}
          swapItems={swapItems}
          setSwapItems={setSwapItems}
          swapRateInfos={swapRateInfos}
          swapStatus={swapStatus}
          setSwapSatus={setSwapSatus}
          isConnected={isConnected}
          handleClickSwap={handleClickSwap}
          showTransactionModal={showTransactionModal}
          handleChangeSwapModal={handleChangeSwapModal}
        />
      </div>
    </div>
  );
}
