/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Dispatch, ReactElement, SetStateAction } from "react";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";

import { getNumberForDisplayUser } from "../../../utils/utils";

import { STAKING_TAB } from "./StakingBox";

interface StakingTabProps {
  accuredRewards?: string;
  isConnected: boolean;
  stakingTab: STAKING_TAB;
  onChangeStakingTab: (tab: STAKING_TAB) => void;
  myWalletCount: number;
  stakedNftCount: number;
  setPage: Dispatch<SetStateAction<number>>;
  resetSelectedNFTItem: () => void;
}

const layoutStakingTab = css`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 22px;
  font-family: "Baloo 2";
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
`;

const itemMyWallet = (isSelected: boolean) => css`
  cursor: pointer;
  color: ${isSelected ? "black" : "#afafaf"};
  padding-right: 30px;
`;

const itemStakedNFT = (isSelected: boolean) => css`
  cursor: pointer;
  color: ${isSelected ? "black" : "#afafaf"};
`;

const stakingTabHeader = (screenWidth: number) => css`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px ${screenWidth > 1212 ? 0 : 30}px;
`;

const myNftTitle = css`
  font-weight: 800;
  font-size: 30px;
  color: #121212;
  margin-bottom: 13px;
`;
const stakingTabWrapper = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 800;
  font-size: 20px;
`;

export default function StakingTab({
  accuredRewards = "0",
  isConnected,
  stakingTab,
  onChangeStakingTab,
  myWalletCount,
  stakedNftCount,
  setPage,
  resetSelectedNFTItem,
}: StakingTabProps): ReactElement {
  const [windowWidth] = useWindowSize();
  const nftAmount = myWalletCount + stakedNftCount;

  const accuredRewardsForDisplayUser = getNumberForDisplayUser(accuredRewards);
  return (
    <>
      <div css={layoutStakingTab}>
        <div css={stakingTabHeader(windowWidth)}>
          <div>
            <div css={myNftTitle}>
              MY NFT
              {isConnected && (
                <span
                  css={css`
                    margin-left: 5px;
                    color: black;
                  `}
                >
                  {"(" + nftAmount + ")"}
                </span>
              )}
            </div>
          </div>

          <div css={stakingTabWrapper}>
            <div
              css={css`
                display: flex;
              `}
            >
              <div
                css={itemMyWallet(stakingTab === STAKING_TAB.MY_WALLET)}
                onClick={() => {
                  onChangeStakingTab(STAKING_TAB.MY_WALLET);
                  setPage(1);
                  resetSelectedNFTItem();
                }}
              >
                MY WALLET {`(${myWalletCount})`}
              </div>
              <div
                css={itemStakedNFT(stakingTab === STAKING_TAB.STAKED_NFT)}
                onClick={() => {
                  onChangeStakingTab(STAKING_TAB.STAKED_NFT);
                  setPage(1);
                  resetSelectedNFTItem();
                }}
              >
                STAKED NFT {`(${stakedNftCount})`}
              </div>
            </div>
            <div
              css={css`
                font-weight: 700;
                font-size: 20px;
              `}
            >
              Accured Rewards
              <span
                css={css`
                  margin-left: 20px;
                  font-weight: 400;
                `}
              >
                {accuredRewardsForDisplayUser} GGNZ
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        css={css`
          margin-top: 50px;
          width: ${windowWidth > 1212 ? 1212 : windowWidth}px;
          height: 0px;

          border: 1px solid #000000;
        `}
      />
    </>
  );
}
