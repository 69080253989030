import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  connectInfoState,
  walletAddressState,
  walletTypeState,
  WALLET_TYPE,
} from "../atom/wallet_address";

import contract from "../contracts/contract.json";
import GGNZContract from "../contracts/abis/GGNZ.json";
import NFT_V1Contract from "../contracts/abis/NFT_V1.json";
import NFT_V2Contract from "../contracts/abis/NFT_V2.json";
import SWAPContract from "../contracts/abis/Swap.json";
import StakingContract from "../contracts/abis/Staking.json";
import Vault_V1Contract from "../contracts/abis/VaultForNFT_V1.json";
import Vault_V2Contract from "../contracts/abis/VaultForNFT_V2.json";
import { init } from "../utils/mintUtils/init";

export default function WalletChecker() {
  const walletAddressValue = useRecoilValue(walletAddressState);
  const walletTypeValue = useRecoilValue(walletTypeState);
  const setConnectInfo = useSetRecoilState(connectInfoState);

  // const handleClickConnectWallet = async (walletAddressValue: string) => {
  //   const walletInfo = await initWithKikas(
  //     walletAddressValue,
  //     "klay_getAccount",
  //     contract,
  //     GGNZContract,
  //     NFT_V1Contract,
  //     NFT_V2Contract,
  //     SWAPContract,
  //     StakingContract,
  //     Vault_V1Contract,
  //     Vault_V2Contract
  //   );

  //   setConnectInfo((prev) => ({ ...prev, ...walletInfo }));
  // };

  useEffect(() => {
    if (walletAddressValue && walletTypeValue) {
      // if (walletTypeValue === WALLET_TYPE.KAIKAS) {
      //   handleClickConnectWallet(walletAddressValue);
      // }
      // const promise = Promise.resolve(
      //   init(
      //     walletAddressValue,
      //     walletTypeValue,
      //     contract,
      //     GGNZContract,
      //     NFT_V1Contract,
      //     NFT_V2Contract,
      //     SWAPContract,
      //     StakingContract,
      //     Vault_V1Contract,
      //     Vault_V2Contract
      //   )
      // );
      // promise.then((data) => {
      //   setConnectInfo((prev) => ({ ...prev, ...data }));
      // });
    }
  }, [walletAddressValue, walletTypeValue]);

  return <></>;
}
