/** @jsxImportSource @emotion/react */
import { ReactElement, useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { useRecoilValue } from "recoil";

import { css } from "@emotion/react";

import { init } from "./utils/mintUtils/init";
import { initialKaikasInfoState } from "./constants/initialState/initial_state";
import { InitialKaikasInfoSchema } from "./constants/schema/initial_state_schema";

import contract from "./contracts/contract.json";
import GGNZContract from "./contracts/abis/GGNZ.json";
import NFT_V1Contract from "./contracts/abis/NFT_V1.json";
import NFT_V2Contract from "./contracts/abis/NFT_V2.json";
import SWAPContract from "./contracts/abis/Swap.json";
import StakingContract from "./contracts/abis/Staking.json";
import Vault_V1Contract from "./contracts/abis/VaultForNFT_V1.json";
import Vault_V2Contract from "./contracts/abis/VaultForNFT_V2.json";

import Header from "./components/header/Header";
import SwapPage from "./components/Swap/SwapPage";
import StakingPage from "./components/Staking/StakingPage";
import GlobalStateContainer from "./store/GlobalStateContainer";
import { WALLET_TYPE } from "./atom/wallet_address";
import { connected } from "process";

const LayoutApp = css`
  width: 100%;
  height: 100%;
`;

function App(): ReactElement {
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [info, setInfo] = useState<InitialKaikasInfoSchema>(
    initialKaikasInfoState
  );

  useEffect(() => {
    if (info.connected) {
      setSnackbarMessage("Connection Successful");
    }
  }, [info.connected]);

  const handleClickConnectWallet = async (
    walletType: WALLET_TYPE,
    walletAccount: string
  ) => {
    const walletInfo = await init(
      walletType,
      contract,
      GGNZContract,
      NFT_V1Contract,
      NFT_V2Contract,
      SWAPContract,
      StakingContract,
      Vault_V1Contract,
      Vault_V2Contract,
      walletAccount
    );

    setInfo((prev) => ({
      ...prev,
      ...walletInfo,
    }));
  };

  useEffect(() => {
    if (snackbarMessage) {
      setTimeout(() => {
        setSnackbarMessage("");
      }, 3000);
    }
  }, [snackbarMessage]);

  const handleChangeSnackBarMessage = (message: string) => {
    setSnackbarMessage(message);
  };

  return (
    <>
      <GlobalStateContainer />
      <div css={LayoutApp}>
        <Header
          account={info.account}
          snackbarMessage={snackbarMessage}
          handleClickConnectWallet={handleClickConnectWallet}
        />
        <Routes>
          <Route
            path="/"
            element={
              <SwapPage
                info={info}
                isConnected={info.connected}
                handleChangeSnackBarMessage={handleChangeSnackBarMessage}
              />
            }
          />
          <Route
            path="staking"
            element={
              <StakingPage
                info={info}
                isConnected={info.connected}
                handleChangeSnackBarMessage={handleChangeSnackBarMessage}
              />
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
