interface ItemArrowLeftProps {
  color?: string;
}
export default function ItemArrowLeft({
  color = "#EAEAEA",
}: ItemArrowLeftProps): JSX.Element {
  return (
    <svg
      width="10"
      height="17"
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.51184 0.49792C10.1627 1.16181 10.1627 2.23819 9.51184 2.90208L4.02369 8.5L9.51184 14.0979C10.1627 14.7618 10.1627 15.8382 9.51184 16.5021C8.86097 17.166 7.80569 17.166 7.15482 16.5021L0.488154 9.70208C-0.16272 9.03819 -0.16272 7.96181 0.488154 7.29792L7.15482 0.49792C7.8057 -0.165971 8.86097 -0.165971 9.51184 0.49792Z"
        fill={color}
      />
    </svg>
  );
}
