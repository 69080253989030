/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ReactElement, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { walletTypeState, WALLET_TYPE } from "../../../atom/wallet_address";
import { STAKING_STATUS } from "../../../constants/enums/enum";
import CountDown from "../../../utils/CountDown";
import QRCode from "qrcode";
import { getKlipResult } from "../../../utils/klipUtils/auth";
import klip_img from "../../../img/klip.png";
const buttonBoxWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 20px;
`;

const swapButtonWrapper = css`
  width: 600px;
  position: relative;
  height: 65px;

  .mint-button-background {
    position: absolute;
    width: 100%;
    background-color: #515151;
    border-radius: 25px;
    z-index: -1;
    bottom: -3px;
    height: 60px;

    &.active {
      background-color: #000;
    }
  }
  .mint-button {
    width: 100%;
    background-color: #eaeaea;
    border-radius: 25px;
    box-shadow: inset 0px -6px 2px 1px #afafaf;
    height: 65px;
    border: solid 3px #515151;
    font-family: "Baloo 2";
    font-weight: 800;
    font-size: 31px;
    color: #afafaf;
    padding-bottom: 3px;
    cursor: pointer;

    &.active {
      background-color: #009b5c;
      box-shadow: 0px -6px 2px 1px #0a5436 inset;
      border: solid 3px #000;
      color: #fff;
      :hover {
        margin-top: 5px;
        box-shadow: inset 0px -1px 2px 1px #0a5436;
      }
    }
  }
`;
const swapModalWrapper = css`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;

  .complete {
    .go-opensea-area {
      position: relative;
      height: 84px;
      margin: 50px auto 0;

      .button-background {
        position: absolute;
        width: calc(100% - 60px);
        background-color: #000;
        border-radius: 25px;
        z-index: -1;
        bottom: -3px;
        height: 80px;
        left: 50%;
        transform: translateX(-50%);
      }
      .go-opensea-button {
        width: calc(100% - 60px);
        border-radius: 25px;
        height: 80px;
        font-family: "Baloo 2";
        font-weight: 800;
        font-size: 22px;
        padding-bottom: 3px;
        cursor: pointer;
        color: #fff;
        background-color: #009b5c;
        box-shadow: 0px -6px 2px 1px #0a5436 inset;
        border: solid 3px #000;
        display: block;
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .go-back {
      border: none;
      font-family: "Baloo 2";
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      color: #000;
      text-align: center;
      width: calc(100% - 60px);
      background: none;
      height: 80px;
      display: block;
      margin: 0 auto;
      margin-top: 8px;
      cursor: pointer;
    }
  }
`;

const layoutModal = (isKlip: boolean) => css`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: ${isKlip ? "#1C6FF1" : "#ffffff"};
  z-index: -200;
  position: absolute;
  width: 600px;
  height: 360px;
  border-radius: 35px;
  padding: 30px;
  color: black;
  font-family: "Baloo 2";
  font-style: normal;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const title = css`
  text-align: center;
  font-size: 30px;
`;

const stakingButtonWrapper = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

interface StakingTabStakingButtonProps {
  selectedNFTItem: string[];
  stakingButtonAvailable: boolean;
  stakingStatus: STAKING_STATUS;
  resetSelectedNFTItem: () => void;
  handleClickStaking: (selectedItems: string[]) => void;
  getStakingQrAddress: (selectedItems: string[]) => Promise<string>;
  updateInfoAfterStaking: (selectedItems: string[], result: boolean) => void;
  handleClickOpenStakingModal: () => void;
}

export default function MyWalletTabStakingButton({
  selectedNFTItem,
  stakingButtonAvailable,
  handleClickStaking,
  stakingStatus,
  resetSelectedNFTItem,
  getStakingQrAddress,
  updateInfoAfterStaking,
  handleClickOpenStakingModal,
}: StakingTabStakingButtonProps): ReactElement {
  const [qr, setQr] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleClickShowOffModal = () => {
    setShowModal(!showModal);
  };
  const walletType = useRecoilValue(walletTypeState);

  const [trySignIn, setTrySignIn] = useState<boolean>(false);
  const [timerStart, setTimerStart] = useState<boolean>(false);
  const [timerDone, setTimerDone] = useState<boolean>(false);

  const [qrImage, setQrImage] = useState<string>("");
  useEffect(() => {
    if (qr && qr !== "err") {
      QRCode.toDataURL(qr).then((url) => {
        setTimerStart(true);
        setQrImage(url);
      });
    }
  }, [qr]);

  useEffect(() => {
    if (timerDone) {
      handleClickShowOffModal();
      resetSelectedNFTItem();
    }
  }, [timerDone]);

  useEffect(() => {
    if (timerStart && !timerDone) {
      const key = qr.split("?request_key=")[1];

      if (key) {
        getKlipResult(key).then((data) => {
          if (data.status !== "completed") {
            setTimeout(() => {
              setTrySignIn((prev) => !prev);
            }, 1000);
          } else {
            updateInfoAfterStaking(selectedNFTItem, true);

            resetSelectedNFTItem();
            setTimerDone(true);
            setShowModal(!showModal);
          }
        });
      }
    }
  }, [timerStart, timerDone, trySignIn]);

  return (
    <>
      {showModal && qrImage && (
        <div css={swapModalWrapper} onClick={handleClickShowOffModal}>
          <div
            css={layoutModal(qrImage && qrImage.length > 0 ? true : false)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          ></div>
          <div
            css={css`
              display: flex;
              margin-top: 240px;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                font-size: 22px;
                line-height: 160%;
                /* or 35px */

                text-align: center;

                color: #ffffff;
              `}
            >
              <span
                css={css`
                  padding-top: 8px;
                  margin-right: 10px;
                `}
              >
                <img width={25} src={klip_img} style={{ padding: "0px" }} />
              </span>
              <span
                css={css`
                  font-family: "CookieRun 400";
                  font-weight: 100;
                `}
              >
                Klip QR 서명
              </span>
            </div>
            <div
              css={css`
                padding: 20px;
                margin-right: 10px;
              `}
            >
              <img src={qrImage} width={150} />
            </div>

            <div
              css={css`
                font-family: "CookieRun 400";
                display: flex;
                justify-content: center;

                font-size: 16px;
                line-height: 160%;
                /* identical to box height, or 26px */
                font-weight: 100;
                text-align: center;

                color: #eaeaea;
              `}
            >
              <span>남은 시간</span>
              {timerStart && <CountDown minNum={5} setDone={setTimerDone} />}
            </div>
            <div
              css={css`
                font-size: 16px;
                line-height: 160%;
                /* identical to box height, or 26px */
                font-family: "CookieRun 400";
                font-weight: 100;
                text-align: center;

                color: #eaeaea;
              `}
            >
              QR코드 리더기 또는 카카오톡 앱으로 QR코드를 스캔해 주세요
            </div>
          </div>
        </div>
      )}
      <div css={stakingButtonWrapper}>
        <div css={buttonBoxWrapper}>
          <div css={swapButtonWrapper}>
            <div
              className={`mint-button-background ${
                stakingButtonAvailable ? "active" : ""
              }`}
            />
            <button
              className={`mint-button ${
                stakingButtonAvailable ? "active" : ""
              }`}
              onClick={() => {
                if (stakingButtonAvailable) {
                  if (walletType === WALLET_TYPE.KAIKAS) {
                    handleClickStaking(selectedNFTItem);
                    resetSelectedNFTItem();
                  } else {
                    const address = getStakingQrAddress(selectedNFTItem);
                    address.then((data) => {
                      setQr(data);
                      setShowModal(true);
                    });
                  }
                }
              }}
            >
              {stakingStatus === STAKING_STATUS.READY && "STAKE"}
              {stakingStatus === STAKING_STATUS.PENDING && "PENDING"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
