/** @jsxImportSource @emotion/react */

import { ReactElement, useEffect, useState } from "react";
import { css } from "@emotion/react";

import { useSetRecoilState } from "recoil";

import { recoilTradeState, TRADE_STATE } from "../../atom/atom";

import { NFT_TYPE, STAKING_STATUS } from "../../constants/enums/enum";
import { initialMyOwnNFTInfo } from "../../constants/initialState/initial_state";

import {
  InitialKaikasInfoSchema,
  MyOwnNFTInfoSchema,
} from "../../constants/schema/initial_state_schema";

import {
  claim,
  getAllClaimedValue,
  getStakedNFT,
  getStakingContractMehodsSchema,
  getTokensURI,
  parseTokensURI,
  staking,
  unstaking,
} from "../../utils/staking_func";

import StakingBox from "./stakingBox/StakingBox";

import {
  klipRequestClaim,
  klipRequestStake,
  klipRequestUnstake,
} from "../../utils/klipUtils/auth";

interface StakingPageProps {
  info: InitialKaikasInfoSchema;
  isConnected: boolean;
  handleChangeSnackBarMessage: (message: string) => void;
}

const layoutSwapPage = (screenWidth: number) => css`
  display: flex;
  font-family: "Baloo 2";
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100% auto;
  max-width: 1312px;
  height: 100vh;
  padding-top: ${screenWidth > 1024 ? 50 : 500}px;
`;

export interface MyNFTInfo {
  unstaked: string[];
  staked: string[];
}

export default function StakingPage({
  info,
  isConnected,
  handleChangeSnackBarMessage,
}: StakingPageProps): ReactElement {
  const screenWidth = screen.width;

  const [stakingStatus, setStakingStatus] = useState<STAKING_STATUS>(
    STAKING_STATUS.READY
  );
  const [page, setPage] = useState<number>(1);
  const setTradeState = useSetRecoilState(recoilTradeState);

  const [myOwnNFTInfo, setMyOwnNFTInfo] =
    useState<MyOwnNFTInfoSchema>(initialMyOwnNFTInfo);

  const [accuredRewards, setAccuredRewards] = useState<string>("0");

  const [dataOfNFT, setDataOfNFT] = useState<any>({
    v1: [],
    v2: [],
    total: [],
  });

  useEffect(() => {
    if (isConnected) {
      const v1: getStakingContractMehodsSchema = {
        contract: info.Vault_V1Contract,
        account: info.account,
        ids: info.nftIds.v1,
        version: 1,
      };

      const v2: getStakingContractMehodsSchema = {
        contract: info.Vault_V2Contract,
        account: info.account,
        ids: info.nftIds.v2,
        version: 2,
      };

      Promise.all([
        getStakedNFT(v1),
        getStakedNFT(v2),
        getAllClaimedValue(v1, v2),
      ])
        .then((data) => {
          const myWalletInfo: MyOwnNFTInfoSchema = {
            stakedNFT: [...data[0], ...data[1]],
            unstakedNFT: [],
          };

          if (v1.ids.length + v2.ids.length !== myWalletInfo.stakedNFT.length) {
            myWalletInfo.unstakedNFT = [
              ...v1.ids.filter((x) => myWalletInfo.stakedNFT.indexOf(x) === -1),
              ...v2.ids.filter((x) => myWalletInfo.stakedNFT.indexOf(x) === -1),
            ];
          }

          setMyOwnNFTInfo(myWalletInfo);
          setAccuredRewards(data[2]);
        })
        .then(() => {
          Promise.all([
            getTokensURI({
              ...v1,
              contract: info.NFT_V1Contract,
            }),
            getTokensURI({
              ...v2,
              contract: info.NFT_V2Contract,
            }),
          ])
            .then(async (data) => {
              return [
                await parseTokensURI(1, data[0]),
                await parseTokensURI(2, data[1]),
              ];
            })
            .then((data) => {
              setDataOfNFT((prev: any) => ({
                ...prev,
                v1: data[0],
                v2: data[1],
                total: [...data[0], ...data[1]],
              }));
            });
        });
    }
  }, [isConnected]);

  const handleClickStaking = async (selectedItems: string[]) => {
    setStakingStatus(STAKING_STATUS.PENDING);
    const v1: getStakingContractMehodsSchema = {
      contract: info.Vault_V1Contract,
      account: info.account,
      ids: [],
      version: 1,
    };
    const v2: getStakingContractMehodsSchema = {
      contract: info.Vault_V2Contract,
      account: info.account,
      ids: [],
      version: 2,
    };

    selectedItems.forEach((x) => {
      const tmp = x.split(" ");
      if (tmp[0] === NFT_TYPE.GOG) {
        v1.ids.push(x);
      } else if (tmp[0] === NFT_TYPE.GOP) {
        v2.ids.push(x);
      }
    });

    const sucessStaking: Promise<boolean> = Promise.resolve(
      staking(v1, v2, info.StakingContract)
    );
    sucessStaking.then(async (result) => {
      if (result) {
        v1.ids = info.nftIds.v1;
        v2.ids = info.nftIds.v2;

        await Promise.all([
          getStakedNFT(v1),
          getStakedNFT(v2),
          getAllClaimedValue(v1, v2),
        ])
          .then((data) => {
            const myWalletInfo: MyOwnNFTInfoSchema = {
              stakedNFT: [...data[0], ...data[1]],
              unstakedNFT: [],
            };

            if (
              v1.ids.length + v2.ids.length !==
              myWalletInfo.stakedNFT.length
            ) {
              myWalletInfo.unstakedNFT = [
                ...v1.ids.filter(
                  (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                ),
                ...v2.ids.filter(
                  (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                ),
              ];
            }
          })
          .then(() => {
            handleChangeSnackBarMessage("Success");
            setTradeState(TRADE_STATE.REFRESH);
            setStakingStatus(STAKING_STATUS.READY);
          })
          .then(() => {
            setPage(1);
            const v1: getStakingContractMehodsSchema = {
              contract: info.Vault_V1Contract,
              account: info.account,
              ids: info.nftIds.v1,
              version: 1,
            };
            const v2: getStakingContractMehodsSchema = {
              contract: info.Vault_V2Contract,
              account: info.account,
              ids: info.nftIds.v2,
              version: 2,
            };

            Promise.all([
              getStakedNFT(v1),
              getStakedNFT(v2),
              getAllClaimedValue(v1, v2),
            ])
              .then((data) => {
                const myWalletInfo: MyOwnNFTInfoSchema = {
                  stakedNFT: [...data[0], ...data[1]],
                  unstakedNFT: [],
                };

                if (
                  v1.ids.length + v2.ids.length !==
                  myWalletInfo.stakedNFT.length
                ) {
                  myWalletInfo.unstakedNFT = [
                    ...v1.ids.filter(
                      (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                    ),
                    ...v2.ids.filter(
                      (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                    ),
                  ];
                }

                setMyOwnNFTInfo(myWalletInfo);
                setAccuredRewards(data[2]);
              })
              .then(() => {
                setTradeState(TRADE_STATE.PENDING);
                Promise.all([
                  getTokensURI({
                    ...v1,
                    contract: info.NFT_V1Contract,
                  }),
                  getTokensURI({
                    ...v2,
                    contract: info.NFT_V2Contract,
                  }),
                ])
                  .then(async (data) => {
                    return [
                      await parseTokensURI(1, data[0]),
                      await parseTokensURI(2, data[1]),
                    ];
                  })
                  .then((data) => {
                    setDataOfNFT((prev: any) => ({
                      ...prev,
                      v1: data[0],
                      v2: data[1],
                      total: [...data[0], ...data[1]],
                    }));
                  });
              });
          });
      }
    });
  };

  const updateInfoAfterStaking = async (
    selectedItems: string[],
    result: boolean
  ) => {
    const v1: getStakingContractMehodsSchema = {
      contract: info.Vault_V1Contract,
      account: info.account,
      ids: [],
      version: 1,
    };
    const v2: getStakingContractMehodsSchema = {
      contract: info.Vault_V2Contract,
      account: info.account,
      ids: [],
      version: 2,
    };

    selectedItems.forEach((x) => {
      const tmp = x.split(" ");
      if (tmp[0] === NFT_TYPE.GOG) {
        v1.ids.push(x);
      } else if (tmp[0] === NFT_TYPE.GOP) {
        v2.ids.push(x);
      }
    });

    if (result) {
      v1.ids = info.nftIds.v1;
      v2.ids = info.nftIds.v2;

      await Promise.all([
        getStakedNFT(v1),
        getStakedNFT(v2),
        getAllClaimedValue(v1, v2),
      ])
        .then((data) => {
          const myWalletInfo: MyOwnNFTInfoSchema = {
            stakedNFT: [...data[0], ...data[1]],
            unstakedNFT: [],
          };

          if (v1.ids.length + v2.ids.length !== myWalletInfo.stakedNFT.length) {
            myWalletInfo.unstakedNFT = [
              ...v1.ids.filter((x) => myWalletInfo.stakedNFT.indexOf(x) === -1),
              ...v2.ids.filter((x) => myWalletInfo.stakedNFT.indexOf(x) === -1),
            ];
          }
        })
        .then(() => {
          handleChangeSnackBarMessage("Success");
          setTradeState(TRADE_STATE.REFRESH);
          setStakingStatus(STAKING_STATUS.READY);
        })
        .then(() => {
          setPage(1);
          const v1: getStakingContractMehodsSchema = {
            contract: info.Vault_V1Contract,
            account: info.account,
            ids: info.nftIds.v1,
            version: 1,
          };
          const v2: getStakingContractMehodsSchema = {
            contract: info.Vault_V2Contract,
            account: info.account,
            ids: info.nftIds.v2,
            version: 2,
          };

          Promise.all([
            getStakedNFT(v1),
            getStakedNFT(v2),
            getAllClaimedValue(v1, v2),
          ])
            .then((data) => {
              const myWalletInfo: MyOwnNFTInfoSchema = {
                stakedNFT: [...data[0], ...data[1]],
                unstakedNFT: [],
              };

              if (
                v1.ids.length + v2.ids.length !==
                myWalletInfo.stakedNFT.length
              ) {
                myWalletInfo.unstakedNFT = [
                  ...v1.ids.filter(
                    (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                  ),
                  ...v2.ids.filter(
                    (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                  ),
                ];
              }

              setMyOwnNFTInfo(myWalletInfo);
              setAccuredRewards(data[2]);
            })
            .then(() => {
              setTradeState(TRADE_STATE.PENDING);
              Promise.all([
                getTokensURI({
                  ...v1,
                  contract: info.NFT_V1Contract,
                }),
                getTokensURI({
                  ...v2,
                  contract: info.NFT_V2Contract,
                }),
              ])
                .then(async (data) => {
                  return [
                    await parseTokensURI(1, data[0]),
                    await parseTokensURI(2, data[1]),
                  ];
                })
                .then((data) => {
                  setDataOfNFT((prev: any) => ({
                    ...prev,
                    v1: data[0],
                    v2: data[1],
                    total: [...data[0], ...data[1]],
                  }));
                });
            });
        });
    }
  };

  const getStakingQrAddress = async (
    selectedItems: string[]
  ): Promise<string> => {
    setStakingStatus(STAKING_STATUS.PENDING);
    const v1: getStakingContractMehodsSchema = {
      contract: info.Vault_V1Contract,
      account: info.account,
      ids: [],
      version: 1,
    };
    const v2: getStakingContractMehodsSchema = {
      contract: info.Vault_V2Contract,
      account: info.account,
      ids: [],
      version: 2,
    };

    selectedItems.forEach((x) => {
      const tmp = x.split(" ");
      if (tmp[0] === NFT_TYPE.GOG) {
        v1.ids.push(x);
      } else if (tmp[0] === NFT_TYPE.GOP) {
        v2.ids.push(x);
      }
    });
    const key = await klipRequestStake(v1, v2, info.StakingContract);

    return key;
  };

  const getClaimQrAddress = async (
    selectedItems: string[]
  ): Promise<string> => {
    const v1: getStakingContractMehodsSchema = {
      contract: info.Vault_V1Contract,
      account: info.account,
      ids: [],
      version: 1,
    };
    const v2: getStakingContractMehodsSchema = {
      contract: info.Vault_V2Contract,
      account: info.account,
      ids: [],
      version: 2,
    };

    selectedItems.forEach((x) => {
      const tmp = x.split(" ");
      if (tmp[0] === NFT_TYPE.GOG) {
        v1.ids.push(x);
      } else if (tmp[0] === NFT_TYPE.GOP) {
        v2.ids.push(x);
      }
    });

    const key = await klipRequestClaim(v1, v2, info.StakingContract);

    return key;
  };

  const updateInfoAfterKlipClaim = (
    selectedItems: string[],
    result: boolean
  ) => {
    const v1: getStakingContractMehodsSchema = {
      contract: info.Vault_V1Contract,
      account: info.account,
      ids: [],
      version: 1,
    };
    const v2: getStakingContractMehodsSchema = {
      contract: info.Vault_V2Contract,
      account: info.account,
      ids: [],
      version: 2,
    };

    selectedItems.forEach((x) => {
      const tmp = x.split(" ");
      if (tmp[0] === NFT_TYPE.GOG) {
        v1.ids.push(x);
      } else if (tmp[0] === NFT_TYPE.GOP) {
        v2.ids.push(x);
      }
    });

    if (result) {
      Promise.all([
        getStakedNFT(v1),
        getStakedNFT(v2),
        getAllClaimedValue(v1, v2),
      ])
        .then((data) => {
          const myWalletInfo: MyOwnNFTInfoSchema = {
            stakedNFT: [...data[0], ...data[1]],
            unstakedNFT: [],
          };

          if (v1.ids.length + v2.ids.length !== myWalletInfo.stakedNFT.length) {
            myWalletInfo.unstakedNFT = [
              ...v1.ids.filter((x) => myWalletInfo.stakedNFT.indexOf(x) === -1),
              ...v2.ids.filter((x) => myWalletInfo.stakedNFT.indexOf(x) === -1),
            ];
          }
        })
        .then(() => {
          handleChangeSnackBarMessage("Success");
        })
        .then(() => {
          const v1: getStakingContractMehodsSchema = {
            contract: info.Vault_V1Contract,
            account: info.account,
            ids: info.nftIds.v1,
            version: 1,
          };
          const v2: getStakingContractMehodsSchema = {
            contract: info.Vault_V2Contract,
            account: info.account,
            ids: info.nftIds.v2,
            version: 2,
          };

          Promise.all([
            getStakedNFT(v1),
            getStakedNFT(v2),
            getAllClaimedValue(v1, v2),
          ])
            .then((data) => {
              const myWalletInfo: MyOwnNFTInfoSchema = {
                stakedNFT: [...data[0], ...data[1]],
                unstakedNFT: [],
              };

              if (
                v1.ids.length + v2.ids.length !==
                myWalletInfo.stakedNFT.length
              ) {
                myWalletInfo.unstakedNFT = [
                  ...v1.ids.filter(
                    (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                  ),
                  ...v2.ids.filter(
                    (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                  ),
                ];
              }

              setMyOwnNFTInfo(myWalletInfo);
              setAccuredRewards(data[2]);
            })
            .then(() => {
              Promise.all([
                getTokensURI({
                  ...v1,
                  contract: info.NFT_V1Contract,
                }),
                getTokensURI({
                  ...v2,
                  contract: info.NFT_V2Contract,
                }),
              ])
                .then(async (data) => {
                  return [
                    await parseTokensURI(1, data[0]),
                    await parseTokensURI(2, data[1]),
                  ];
                })
                .then((data) => {
                  setTradeState(TRADE_STATE.REFRESH);
                  setDataOfNFT((prev: any) => ({
                    ...prev,
                    v1: data[0],
                    v2: data[1],
                    total: [...data[0], ...data[1]],
                  }));
                })
                .then(() => {
                  setTradeState(TRADE_STATE.PENDING);
                });
            });
        });
    }
  };

  const handleClickClaim = (selectedItems: string[]) => {
    const v1: getStakingContractMehodsSchema = {
      contract: info.Vault_V1Contract,
      account: info.account,
      ids: [],
      version: 1,
    };
    const v2: getStakingContractMehodsSchema = {
      contract: info.Vault_V2Contract,
      account: info.account,
      ids: [],
      version: 2,
    };

    selectedItems.forEach((x) => {
      const tmp = x.split(" ");
      if (tmp[0] === NFT_TYPE.GOG) {
        v1.ids.push(x);
      } else if (tmp[0] === NFT_TYPE.GOP) {
        v2.ids.push(x);
      }
    });

    const sucessClaim: Promise<boolean> = Promise.resolve(
      claim(v1, v2, info.StakingContract)
    );

    sucessClaim.then((result) => {
      v1.ids = info.nftIds.v1;
      v2.ids = info.nftIds.v2;
      if (result) {
        Promise.all([
          getStakedNFT(v1),
          getStakedNFT(v2),
          getAllClaimedValue(v1, v2),
        ])
          .then((data) => {
            const myWalletInfo: MyOwnNFTInfoSchema = {
              stakedNFT: [...data[0], ...data[1]],
              unstakedNFT: [],
            };

            if (
              v1.ids.length + v2.ids.length !==
              myWalletInfo.stakedNFT.length
            ) {
              myWalletInfo.unstakedNFT = [
                ...v1.ids.filter(
                  (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                ),
                ...v2.ids.filter(
                  (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                ),
              ];
            }
          })
          .then(() => {
            handleChangeSnackBarMessage("Success");
          })
          .then(() => {
            const v1: getStakingContractMehodsSchema = {
              contract: info.Vault_V1Contract,
              account: info.account,
              ids: info.nftIds.v1,
              version: 1,
            };
            const v2: getStakingContractMehodsSchema = {
              contract: info.Vault_V2Contract,
              account: info.account,
              ids: info.nftIds.v2,
              version: 2,
            };

            Promise.all([
              getStakedNFT(v1),
              getStakedNFT(v2),
              getAllClaimedValue(v1, v2),
            ])
              .then((data) => {
                const myWalletInfo: MyOwnNFTInfoSchema = {
                  stakedNFT: [...data[0], ...data[1]],
                  unstakedNFT: [],
                };

                if (
                  v1.ids.length + v2.ids.length !==
                  myWalletInfo.stakedNFT.length
                ) {
                  myWalletInfo.unstakedNFT = [
                    ...v1.ids.filter(
                      (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                    ),
                    ...v2.ids.filter(
                      (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                    ),
                  ];
                }

                setMyOwnNFTInfo(myWalletInfo);
                setAccuredRewards(data[2]);
              })
              .then(() => {
                Promise.all([
                  getTokensURI({
                    ...v1,
                    contract: info.NFT_V1Contract,
                  }),
                  getTokensURI({
                    ...v2,
                    contract: info.NFT_V2Contract,
                  }),
                ])
                  .then(async (data) => {
                    return [
                      await parseTokensURI(1, data[0]),
                      await parseTokensURI(2, data[1]),
                    ];
                  })
                  .then((data) => {
                    setTradeState(TRADE_STATE.REFRESH);
                    setDataOfNFT((prev: any) => ({
                      ...prev,
                      v1: data[0],
                      v2: data[1],
                      total: [...data[0], ...data[1]],
                    }));
                  })
                  .then(() => {
                    setTradeState(TRADE_STATE.PENDING);
                  });
              });
          });
      }
    });
  };

  const getUnstakingQrAddress = async (
    selectedItems: string[]
  ): Promise<string> => {
    const v1: getStakingContractMehodsSchema = {
      contract: info.Vault_V1Contract,
      account: info.account,
      ids: [],
      version: 1,
    };
    const v2: getStakingContractMehodsSchema = {
      contract: info.Vault_V2Contract,
      account: info.account,
      ids: [],
      version: 2,
    };

    selectedItems.forEach((x) => {
      const tmp = x.split(" ");
      if (tmp[0] === NFT_TYPE.GOG) {
        v1.ids.push(x);
      } else if (tmp[0] === NFT_TYPE.GOP) {
        v2.ids.push(x);
      }
    });

    const key = await klipRequestUnstake(v1, v2, info.StakingContract);

    return key;
  };

  const updateInfoAFterKlipUnstaking = (
    selectedItems: string[],
    result: boolean
  ) => {
    const v1: getStakingContractMehodsSchema = {
      contract: info.Vault_V1Contract,
      account: info.account,
      ids: [],
      version: 1,
    };
    const v2: getStakingContractMehodsSchema = {
      contract: info.Vault_V2Contract,
      account: info.account,
      ids: [],
      version: 2,
    };

    selectedItems.forEach((x) => {
      const tmp = x.split(" ");
      if (tmp[0] === NFT_TYPE.GOG) {
        v1.ids.push(x);
      } else if (tmp[0] === NFT_TYPE.GOP) {
        v2.ids.push(x);
      }
    });

    if (result) {
      v1.ids = info.nftIds.v1;
      v2.ids = info.nftIds.v2;

      Promise.all([
        getStakedNFT(v1),
        getStakedNFT(v2),
        getAllClaimedValue(v1, v2),
      ])
        .then((data) => {
          const myWalletInfo: MyOwnNFTInfoSchema = {
            stakedNFT: [...data[0], ...data[1]],
            unstakedNFT: [],
          };

          if (v1.ids.length + v2.ids.length !== myWalletInfo.stakedNFT.length) {
            myWalletInfo.unstakedNFT = [
              ...v1.ids.filter((x) => myWalletInfo.stakedNFT.indexOf(x) === -1),
              ...v2.ids.filter((x) => myWalletInfo.stakedNFT.indexOf(x) === -1),
            ];
          }
        })
        .then(() => {
          setTradeState(TRADE_STATE.REFRESH);
          handleChangeSnackBarMessage("Success");
        })
        .then(() => {
          const v1: getStakingContractMehodsSchema = {
            contract: info.Vault_V1Contract,
            account: info.account,
            ids: info.nftIds.v1,
            version: 1,
          };
          const v2: getStakingContractMehodsSchema = {
            contract: info.Vault_V2Contract,
            account: info.account,
            ids: info.nftIds.v2,
            version: 2,
          };

          Promise.all([
            getStakedNFT(v1),
            getStakedNFT(v2),
            getAllClaimedValue(v1, v2),
          ])
            .then((data) => {
              const myWalletInfo: MyOwnNFTInfoSchema = {
                stakedNFT: [...data[0], ...data[1]],
                unstakedNFT: [],
              };

              if (
                v1.ids.length + v2.ids.length !==
                myWalletInfo.stakedNFT.length
              ) {
                myWalletInfo.unstakedNFT = [
                  ...v1.ids.filter(
                    (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                  ),
                  ...v2.ids.filter(
                    (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                  ),
                ];
              }

              setMyOwnNFTInfo(myWalletInfo);
              setAccuredRewards(data[2]);
            })
            .then(() => {
              setTradeState(TRADE_STATE.PENDING);
              Promise.all([
                getTokensURI({
                  ...v1,
                  contract: info.NFT_V1Contract,
                }),
                getTokensURI({
                  ...v2,
                  contract: info.NFT_V2Contract,
                }),
              ])
                .then(async (data) => {
                  return [
                    await parseTokensURI(1, data[0]),
                    await parseTokensURI(2, data[1]),
                  ];
                })
                .then((data) => {
                  setDataOfNFT((prev: any) => ({
                    ...prev,
                    v1: data[0],
                    v2: data[1],
                    total: [...data[0], ...data[1]],
                  }));
                });
            });
        })
        .then(() => {
          setPage(1);
          const v1: getStakingContractMehodsSchema = {
            contract: info.Vault_V1Contract,
            account: info.account,
            ids: info.nftIds.v1,
            version: 1,
          };
          const v2: getStakingContractMehodsSchema = {
            contract: info.Vault_V2Contract,
            account: info.account,
            ids: info.nftIds.v2,
            version: 2,
          };

          Promise.all([
            getStakedNFT(v1),
            getStakedNFT(v2),
            getAllClaimedValue(v1, v2),
          ])
            .then((data) => {
              const myWalletInfo: MyOwnNFTInfoSchema = {
                stakedNFT: [...data[0], ...data[1]],
                unstakedNFT: [],
              };

              if (
                v1.ids.length + v2.ids.length !==
                myWalletInfo.stakedNFT.length
              ) {
                myWalletInfo.unstakedNFT = [
                  ...v1.ids.filter(
                    (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                  ),
                  ...v2.ids.filter(
                    (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                  ),
                ];
              }

              setMyOwnNFTInfo(myWalletInfo);
              setAccuredRewards(data[2]);
            })
            .then(() => {
              Promise.all([
                getTokensURI({
                  ...v1,
                  contract: info.NFT_V1Contract,
                }),
                getTokensURI({
                  ...v2,
                  contract: info.NFT_V2Contract,
                }),
              ])
                .then(async (data) => {
                  return [
                    await parseTokensURI(1, data[0]),
                    await parseTokensURI(2, data[1]),
                  ];
                })
                .then((data) => {
                  setDataOfNFT((prev: any) => ({
                    ...prev,
                    v1: data[0],
                    v2: data[1],
                    total: [...data[0], ...data[1]],
                  }));
                });
            });
        });
    }
  };

  const handleClickUnstaking = (selectedItems: string[]) => {
    const v1: getStakingContractMehodsSchema = {
      contract: info.Vault_V1Contract,
      account: info.account,
      ids: [],
      version: 1,
    };
    const v2: getStakingContractMehodsSchema = {
      contract: info.Vault_V2Contract,
      account: info.account,
      ids: [],
      version: 2,
    };

    selectedItems.forEach((x) => {
      const tmp = x.split(" ");
      if (tmp[0] === NFT_TYPE.GOG) {
        v1.ids.push(x);
      } else if (tmp[0] === NFT_TYPE.GOP) {
        v2.ids.push(x);
      }
    });

    const sucessUnstaking: Promise<boolean> = Promise.resolve(
      unstaking(v1, v2, info.StakingContract)
    );
    sucessUnstaking
      .then((result) => {
        v1.ids = info.nftIds.v1;
        v2.ids = info.nftIds.v2;
        if (result) {
          Promise.all([
            getStakedNFT(v1),
            getStakedNFT(v2),
            getAllClaimedValue(v1, v2),
          ])
            .then((data) => {
              const myWalletInfo: MyOwnNFTInfoSchema = {
                stakedNFT: [...data[0], ...data[1]],
                unstakedNFT: [],
              };

              if (
                v1.ids.length + v2.ids.length !==
                myWalletInfo.stakedNFT.length
              ) {
                myWalletInfo.unstakedNFT = [
                  ...v1.ids.filter(
                    (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                  ),
                  ...v2.ids.filter(
                    (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                  ),
                ];
              }
            })
            .then(() => {
              setTradeState(TRADE_STATE.REFRESH);
              handleChangeSnackBarMessage("Success");
            })
            .then(() => {
              const v1: getStakingContractMehodsSchema = {
                contract: info.Vault_V1Contract,
                account: info.account,
                ids: info.nftIds.v1,
                version: 1,
              };
              const v2: getStakingContractMehodsSchema = {
                contract: info.Vault_V2Contract,
                account: info.account,
                ids: info.nftIds.v2,
                version: 2,
              };

              Promise.all([
                getStakedNFT(v1),
                getStakedNFT(v2),
                getAllClaimedValue(v1, v2),
              ])
                .then((data) => {
                  const myWalletInfo: MyOwnNFTInfoSchema = {
                    stakedNFT: [...data[0], ...data[1]],
                    unstakedNFT: [],
                  };

                  if (
                    v1.ids.length + v2.ids.length !==
                    myWalletInfo.stakedNFT.length
                  ) {
                    myWalletInfo.unstakedNFT = [
                      ...v1.ids.filter(
                        (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                      ),
                      ...v2.ids.filter(
                        (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                      ),
                    ];
                  }

                  setMyOwnNFTInfo(myWalletInfo);
                  setAccuredRewards(data[2]);
                })
                .then(() => {
                  setTradeState(TRADE_STATE.PENDING);
                  Promise.all([
                    getTokensURI({
                      ...v1,
                      contract: info.NFT_V1Contract,
                    }),
                    getTokensURI({
                      ...v2,
                      contract: info.NFT_V2Contract,
                    }),
                  ])
                    .then(async (data) => {
                      return [
                        await parseTokensURI(1, data[0]),
                        await parseTokensURI(2, data[1]),
                      ];
                    })
                    .then((data) => {
                      setDataOfNFT((prev: any) => ({
                        ...prev,
                        v1: data[0],
                        v2: data[1],
                        total: [...data[0], ...data[1]],
                      }));
                    });
                });
            });
        }
      })
      .then(() => {
        setPage(1);
        const v1: getStakingContractMehodsSchema = {
          contract: info.Vault_V1Contract,
          account: info.account,
          ids: info.nftIds.v1,
          version: 1,
        };
        const v2: getStakingContractMehodsSchema = {
          contract: info.Vault_V2Contract,
          account: info.account,
          ids: info.nftIds.v2,
          version: 2,
        };

        Promise.all([
          getStakedNFT(v1),
          getStakedNFT(v2),
          getAllClaimedValue(v1, v2),
        ])
          .then((data) => {
            const myWalletInfo: MyOwnNFTInfoSchema = {
              stakedNFT: [...data[0], ...data[1]],
              unstakedNFT: [],
            };

            if (
              v1.ids.length + v2.ids.length !==
              myWalletInfo.stakedNFT.length
            ) {
              myWalletInfo.unstakedNFT = [
                ...v1.ids.filter(
                  (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                ),
                ...v2.ids.filter(
                  (x) => myWalletInfo.stakedNFT.indexOf(x) === -1
                ),
              ];
            }

            setMyOwnNFTInfo(myWalletInfo);
            setAccuredRewards(data[2]);
          })
          .then(() => {
            Promise.all([
              getTokensURI({
                ...v1,
                contract: info.NFT_V1Contract,
              }),
              getTokensURI({
                ...v2,
                contract: info.NFT_V2Contract,
              }),
            ])
              .then(async (data) => {
                return [
                  await parseTokensURI(1, data[0]),
                  await parseTokensURI(2, data[1]),
                ];
              })
              .then((data) => {
                setDataOfNFT((prev: any) => ({
                  ...prev,
                  v1: data[0],
                  v2: data[1],
                  total: [...data[0], ...data[1]],
                }));
              });
          });
      });
  };

  return (
    <div css={layoutSwapPage(screenWidth)}>
      <StakingBox
        info={info}
        page={page}
        setPage={setPage}
        isConnected={isConnected}
        stakingStatus={stakingStatus}
        myOwnNFTInfo={myOwnNFTInfo}
        accuredRewards={accuredRewards}
        dataOfNFT={dataOfNFT}
        handleClickStaking={handleClickStaking}
        handleClickClaim={handleClickClaim}
        handleClickUnstaking={handleClickUnstaking}
        getStakingQrAddress={getStakingQrAddress}
        getClaimQrAddress={getClaimQrAddress}
        getUnstakingQrAddress={getUnstakingQrAddress}
        updateInfoAfterStaking={updateInfoAfterStaking}
        updateInfoAfterKlipClaim={updateInfoAfterKlipClaim}
        updateInfoAFterKlipUnstaking={updateInfoAFterKlipUnstaking}
      />
    </div>
  );
}
